@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-35Thin.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-35Thin.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-35Thin.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-35Thin.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-35Thin.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-35Thin.svg") format("svg");
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-35Thin.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-35Thin.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-35Thin.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-35Thin.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-35Thin.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-35Thin.svg") format("svg");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-45Light.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-45Light.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-45Light.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-45Light.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-45Light.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-45Light.svg") format("svg");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-45Light.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-45Light.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-45Light.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-45Light.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-45Light.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-45Light.svg") format("svg");
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-55Roman.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-55Roman.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-55Roman.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-55Roman.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-55Roman.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-55Roman.svg") format("svg");
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-55Roman.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-55Roman.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-55Roman.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-55Roman.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-55Roman.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-55Roman.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-56Italic.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-56Italic.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-56Italic.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-56Italic.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-56Italic.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-56Italic.svg") format("svg");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-65Medium.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-65Medium.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-65Medium.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-65Medium.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-65Medium.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-65Medium.svg") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-65Medium.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-65Medium.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-65Medium.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-65Medium.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-65Medium.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-65Medium.svg") format("svg");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-75Bold.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-75Bold.svg") format("svg");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-75Bold.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-75Bold.svg") format("svg");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-75Bold.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-75Bold.svg") format("svg");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src:     url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix");
    src: url("../fonts/HelveticaNeueLTW01-75Bold.eot?#iefix") format("eot"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTW01-75Bold.woff") format("woff"),
         url("../fonts/HelveticaNeueLTW01-75Bold.ttf") format("truetype"),
         url("../fonts/HelveticaNeueLTW01-75Bold.svg") format("svg");
    font-weight: 900;
    font-style: normal;
}



