

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background: #f7f7f7;
}


