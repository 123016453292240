@font-face{
    font-family:icomoon;
    src:url("../fonts/icomoon-old.eot?axzxrh");
    src:url("../fonts/icomoon-old.eot?axzxrh#iefix") format('embedded-opentype'),url(../fonts/icomoon-old.ttf?axzxrh) format('truetype'),url("../fonts/icomoon-old.woff?axzxrh") format('woff'),url("../fonts/icomoon-old.svg?axzxrh#icomoon") format('svg');
    font-weight:400;
    font-style:normal;
    font-display:block
}
[class*=" icon-"],[class^=icon-]{
    font-family:icomoon!important;
    speak:none;
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.icon-32-addressBook-bold:before{
    font-size:32px;
    content:"\e900"
}
.icon-32-addressBook-mono:before{
    font-size:32px;
    content:"\e901"
}
.icon-32-alert-bold:before{
    font-size:32px;
    content:"\e902"
}
.icon-32-alert-mono:before{
    font-size:32px;
    content:"\e903"
}
.icon-32-align-center-bold:before{
    font-size:32px;
    content:"\e904"
}
.icon-32-align-center-mono:before{
    font-size:32px;
    content:"\e905"
}
.icon-32-align-left-bold:before{
    font-size:32px;
    content:"\e906"
}
.icon-32-align-left-mono:before{
    font-size:32px;
    content:"\e907"
}
.icon-32-align-right-bold:before{
    font-size:32px;
    content:"\e908"
}
.icon-32-align-right-mono:before{
    font-size:32px;
    content:"\e909"
}
.icon-32-analyze-bold:before{
    font-size:32px;
    content:"\e90a"
}
.icon-32-analyze-mono:before{
    font-size:32px;
    content:"\e90b"
}
.icon-32-arrow-down-bold:before{
    font-size:32px;
    content:"\e90c"
}
.icon-32-arrow-down-mono:before{
    font-size:32px;
    content:"\e90d"
}
.icon-32-arrow-left-bold:before{
    font-size:32px;
    content:"\e90e"
}
.icon-32-arrow-left-mono:before{
    font-size:32px;
    content:"\e90f"
}
.icon-32-arrow-right-bold:before{
    font-size:32px;
    content:"\e910"
}
.icon-32-arrow-right-mono:before{
    font-size:32px;
    content:"\e911"
}
.icon-32-arrow-up-bold:before{
    font-size:32px;
    content:"\e912"
}
.icon-32-arrow-up-mono:before{
    font-size:32px;
    content:"\e913"
}
.icon-32-asterisk-bold:before{
    font-size:32px;
    content:"\e914"
}
.icon-32-asterisk-mono:before{
    font-size:32px;
    content:"\e915"
}
.icon-32-attachment-mono:before{
    font-size:32px;
    content:"\e916"
}
.icon-32-awake-bold:before{
    font-size:32px;
    content:"\e917"
}
.icon-32-awake-mono:before{
    font-size:32px;
    content:"\e918"
}
.icon-32-award-bold:before{
    font-size:32px;
    content:"\e919"
}
.icon-32-award-mono:before{
    font-size:32px;
    content:"\e91a"
}
.icon-32-barcode-bold:before{
    font-size:32px;
    content:"\e91b"
}
.icon-32-barcode-mono:before{
    font-size:32px;
    content:"\e91c"
}
.icon-32-bell-bold:before{
    font-size:32px;
    content:"\e91d"
}
.icon-32-bell-mono:before{
    font-size:32px;
    content:"\e91e"
}
.icon-32-bin-bold:before{
    font-size:32px;
    content:"\e91f"
}
.icon-32-bin-mono:before{
    font-size:32px;
    content:"\e920"
}
.icon-32-bluetooth-mono:before{
    font-size:32px;
    content:"\e921"
}
.icon-32-bookmark-bold:before{
    font-size:32px;
    content:"\e922"
}
.icon-32-bookmark-mono:before{
    font-size:32px;
    content:"\e923"
}
.icon-32-bulletedList-bold:before{
    font-size:32px;
    content:"\e924"
}
.icon-32-bulletedList-mono:before{
    font-size:32px;
    content:"\e925"
}
.icon-32-caculator-bold:before{
    font-size:32px;
    content:"\e926"
}
.icon-32-caculator-mono:before{
    font-size:32px;
    content:"\e927"
}
.icon-32-calendar-bold:before{
    font-size:32px;
    content:"\e928"
}
.icon-32-calendar-mono:before{
    font-size:32px;
    content:"\e929"
}
.icon-32-camera-bold:before{
    font-size:32px;
    content:"\e92a"
}
.icon-32-camera-mono:before{
    font-size:32px;
    content:"\e92b"
}
.icon-32-cart-add-bold:before{
    font-size:32px;
    content:"\e92c"
}
.icon-32-cart-add-mono:before{
    font-size:32px;
    content:"\e92d"
}
.icon-32-cart-default-bold:before{
    font-size:32px;
    content:"\e92e"
}
.icon-32-cart-default-mono:before{
    font-size:32px;
    content:"\e92f"
}
.icon-32-cart-remove-bold:before{
    font-size:32px;
    content:"\e930"
}
.icon-32-cart-remove-mono:before{
    font-size:32px;
    content:"\e931"
}
.icon-32-catalog-bold:before{
    font-size:32px;
    content:"\e932"
}
.icon-32-catalog-mono:before{
    font-size:32px;
    content:"\e933"
}
.icon-32-chat-bold:before{
    font-size:32px;
    content:"\e934"
}
.icon-chat-mono:before{
    font-size:32px;
    content:"\e935"
}
.icon-32-checkedBox-bold:before{
    font-size:32px;
    content:"\e936"
}
.icon-32-checkedBox-mono:before{
    font-size:32px;
    content:"\e937"
}
.icon-32-checkmark-bold:before{
    font-size:32px;
    content:"\e938"
}
.icon-32-checkmark-mono:before{
    font-size:32px;
    content:"\e939"
}
.icon-32-chevron-down-bold:before{
    font-size:32px;
    content:"\e93a"
}
.icon-32-chevron-down-mono:before{
    font-size:32px;
    content:"\e93b"
}
.icon-32-chevron-left-bold:before{
    font-size:32px;
    content:"\e93c"
}
.icon-32-chevron-left-mono:before{
    font-size:32px;
    content:"\e93d"
}
.icon-32-chevron-right-bold:before{
    font-size:32px;
    content:"\e93e"
}
.icon-32-chevron-right-mono:before{
    font-size:32px;
    content:"\e93f"
}
.icon-32-chevron-up-bold:before{
    font-size:32px;
    content:"\e940"
}
.icon-32-chevron-up-mono:before{
    font-size:32px;
    content:"\e941"
}
.icon-32-clipboard-bold:before{
    font-size:32px;
    content:"\e942"
}
.icon-32-clipboard-mono:before{
    font-size:32px;
    content:"\e943"
}
.icon-32-clock-bold:before{
    font-size:32px;
    content:"\e944"
}
.icon-32-clock-mono:before{
    font-size:32px;
    content:"\e945"
}
.icon-32-close-bold:before{
    font-size:32px;
    content:"\e946"
}
.icon-32-close-mono:before{
    font-size:32px;
    content:"\e947"
}
.icon-32-cloud-bold:before{
    font-size:32px;
    content:"\e948"
}
.icon-32-cloud-mono:before{
    font-size:32px;
    content:"\e949"
}
.icon-32-cloudDownload-bold:before{
    font-size:32px;
    content:"\e94a"
}
.icon-32-cloudDownload-mono:before{
    font-size:32px;
    content:"\e94b"
}
.icon-32-cloudUpload-bold:before{
    font-size:32px;
    content:"\e94c"
}
.icon-32-cloudUpload-mono:before{
    font-size:32px;
    content:"\e94d"
}
.icon-32-cold-bold:before{
    font-size:32px;
    content:"\e94e"
}
.icon-32-cold-mono:before{
    font-size:32px;
    content:"\e94f"
}
.icon-32-comment-bold:before{
    font-size:32px;
    content:"\e950"
}
.icon-32-comment-mono:before{
    font-size:32px;
    content:"\e951"
}
.icon-32-community-bold:before{
    font-size:32px;
    content:"\e952"
}
.icon-32-community-mono:before{
    font-size:32px;
    content:"\e953"
}
.icon-32-compass-bold:before{
    font-size:32px;
    content:"\e954"
}
.icon-32-compass-mono:before{
    font-size:32px;
    content:"\e955"
}
.icon-32-contrast-bold:before{
    font-size:32px;
    content:"\e956"
}
.icon-32-contrast-mono:before{
    font-size:32px;
    content:"\e957"
}
.icon-32-creditCard-bold:before{
    font-size:32px;
    content:"\e958"
}
.icon-32-creditCard-mono:before{
    font-size:32px;
    content:"\e959"
}
.icon-32-crop-bold:before{
    font-size:32px;
    content:"\e95a"
}
.icon-32-crop-mono:before{
    font-size:32px;
    content:"\e95b"
}
.icon-32-customerApproved-bold:before{
    font-size:32px;
    content:"\e95c"
}
.icon-32-customerApproved-mono:before{
    font-size:32px;
    content:"\e95d"
}
.icon-32-cut-bold:before{
    font-size:32px;
    content:"\e95e"
}
.icon-32-cut-mono:before{
    font-size:32px;
    content:"\e95f"
}
.icon-32-dashboard-bold:before{
    font-size:32px;
    content:"\e960"
}
.icon-32-dashboard-mono:before{
    font-size:32px;
    content:"\e961"
}
.icon-32-data-bold:before{
    font-size:32px;
    content:"\e962"
}
.icon-32-data-mono:before{
    font-size:32px;
    content:"\e963"
}
.icon-32-database-ban-bold:before{
    font-size:32px;
    content:"\e964"
}
.icon-32-database-ban-mono:before{
    font-size:32px;
    content:"\e965"
}
.icon-32-database-default-bold:before{
    font-size:32px;
    content:"\e966"
}
.icon-32-database-default-mono:before{
    font-size:32px;
    content:"\e967"
}
.icon-32-database-lock-bold:before{
    font-size:32px;
    content:"\e968"
}
.icon-32-database-lock-mono:before{
    font-size:32px;
    content:"\e969"
}
.icon-32-database-minus-bold:before{
    font-size:32px;
    content:"\e96a"
}
.icon-32-database-minus-mono:before{
    font-size:32px;
    content:"\e96b"
}
.icon-32-database-plus-mono:before{
    font-size:32px;
    content:"\e96c"
}
.icon-32-database-plus-bold:before{
    font-size:32px;
    content:"\e96d"
}
.icon-32-delete-bold:before{
    font-size:32px;
    content:"\e96e"
}
.icon-32-delete-mono:before{
    font-size:32px;
    content:"\e96f"
}
.icon-32-direction-bold:before{
    font-size:32px;
    content:"\e970"
}
.icon-32-direction-mono:before{
    font-size:32px;
    content:"\e971"
}
.icon-32-divide-bold:before{
    font-size:32px;
    content:"\e972"
}
.icon-32-divide-mono:before{
    font-size:32px;
    content:"\e973"
}
.icon-32-docs-7z-bold:before{
    font-size:32px;
    content:"\e974"
}
.icon-32-docs-7z-mono:before{
    font-size:32px;
    content:"\e975"
}
.icon-32-docs-cat-bold:before{
    font-size:32px;
    content:"\e976"
}
.icon-32-docs-cat-mono:before{
    font-size:32px;
    content:"\e977"
}
.icon-32-docs-css-bold:before{
    font-size:32px;
    content:"\e978"
}
.icon-32-docs-css-mono:before{
    font-size:32px;
    content:"\e979"
}
.icon-32-docs-csv-bold:before{
    font-size:32px;
    content:"\e97a"
}
.icon-32-docs-csv-mono:before{
    font-size:32px;
    content:"\e97b"
}
.icon-32-docs-doc-bold:before{
    font-size:32px;
    content:"\e97c"
}
.icon-32-docs-doc-mono:before{
    font-size:32px;
    content:"\e97d"
}
.icon-32-docs-eps-bold:before{
    font-size:32px;
    content:"\e97e"
}
.icon-32-docs-eps-mono:before{
    font-size:32px;
    content:"\e97f"
}
.icon-32-docs-exe-bold:before{
    font-size:32px;
    content:"\e980"
}
.icon-32-docs-exe-mono:before{
    font-size:32px;
    content:"\e981"
}
.icon-32-docs-gif-bold:before{
    font-size:32px;
    content:"\e982"
}
.icon-32-docs-gif-mono:before{
    font-size:32px;
    content:"\e983"
}
.icon-32-docs-gz-bold:before{
    font-size:32px;
    content:"\e984"
}
.icon-32-docs-gz-mono:before{
    font-size:32px;
    content:"\e985"
}
.icon-32-docs-ics-bold:before{
    font-size:32px;
    content:"\e986"
}
.icon-32-docs-ics-mono:before{
    font-size:32px;
    content:"\e987"
}
.icon-32-docs-img-bold:before{
    font-size:32px;
    content:"\e988"
}
.icon-32-docs-img-mono:before{
    font-size:32px;
    content:"\e989"
}
.icon-32-docs-jpg-bold:before{
    font-size:32px;
    content:"\e98a"
}
.icon-32-docs-jpg-mono:before{
    font-size:32px;
    content:"\e98b"
}
.icon-32-docs-m4v-bold:before{
    font-size:32px;
    content:"\e98c"
}
.icon-32-docs-m4v-mono:before{
    font-size:32px;
    content:"\e98d"
}
.icon-32-docs-mp3-bold:before{
    font-size:32px;
    content:"\e98e"
}
.icon-32-docs-mp3-mono:before{
    font-size:32px;
    content:"\e98f"
}
.icon-32-docs-mp4-bold:before{
    font-size:32px;
    content:"\e990"
}
.icon-32-docs-mp4-mono:before{
    font-size:32px;
    content:"\e991"
}
.icon-32-docs-pdf-bold:before{
    font-size:32px;
    content:"\e992"
}
.icon-32-docs-pdf-mono:before{
    font-size:32px;
    content:"\e993"
}
.icon-32-docs-png-bold:before{
    font-size:32px;
    content:"\e994"
}
.icon-32-docs-png-mono:before{
    font-size:32px;
    content:"\e995"
}
.icon-32-docs-pps-bold:before{
    font-size:32px;
    content:"\e996"
}
.icon-32-docs-pps-mono:before{
    font-size:32px;
    content:"\e997"
}
.icon-32-docs-ppt-bold:before{
    font-size:32px;
    content:"\e998"
}
.icon-32-docs-ppt-mono:before{
    font-size:32px;
    content:"\e999"
}
.icon-32-docs-psd-bold:before{
    font-size:32px;
    content:"\e99a"
}
.icon-32-docs-psd-mono:before{
    font-size:32px;
    content:"\e99b"
}
.icon-32-docs-rar-bold:before{
    font-size:32px;
    content:"\e99c"
}
.icon-32-docs-rar-mono:before{
    font-size:32px;
    content:"\e99d"
}
.icon-32-docs-swf-bold:before{
    font-size:32px;
    content:"\e99e"
}
.icon-32-docs-swf-mono:before{
    font-size:32px;
    content:"\e99f"
}
.icon-32-docs-tar-bold:before{
    font-size:32px;
    content:"\e9a0"
}
.icon-32-docs-tar-mono:before{
    font-size:32px;
    content:"\e9a1"
}
.icon-32-docs-tgz-bold:before{
    font-size:32px;
    content:"\e9a2"
}
.icon-32-docs-tgz-mono:before{
    font-size:32px;
    content:"\e9a3"
}
.icon-32-docs-tif-bold:before{
    font-size:32px;
    content:"\e9a4"
}
.icon-32-docs-tif-mono:before{
    font-size:32px;
    content:"\e9a5"
}
.icon-32-docs-txt-bold:before{
    font-size:32px;
    content:"\e9a6"
}
.icon-32-docs-txt-mono:before{
    font-size:32px;
    content:"\e9a7"
}
.icon-32-docs-vcf-bold:before{
    font-size:32px;
    content:"\e9a8"
}
.icon-32-docs-vcf-mono:before{
    font-size:32px;
    content:"\e9a9"
}
.icon-32-docs-wmf-bold:before{
    font-size:32px;
    content:"\e9aa"
}
.icon-32-docs-wmf-mono:before{
    font-size:32px;
    content:"\e9ab"
}
.icon-32-docs-wmv-bold:before{
    font-size:32px;
    content:"\e9ac"
}
.icon-32-docs-wmv-mono:before{
    font-size:32px;
    content:"\e9ad"
}
.icon-32-docs-xls-bold:before{
    font-size:32px;
    content:"\e9ae"
}
.icon-32-docs-xls-mono:before{
    font-size:32px;
    content:"\e9af"
}
.icon-32-docs-xml-bold:before{
    font-size:32px;
    content:"\e9b0"
}
.icon-32-docs-xml-mono:before{
    font-size:32px;
    content:"\e9b1"
}
.icon-32-docs-zip-bold:before{
    font-size:32px;
    content:"\e9b2"
}
.icon-32-docs-zip-mono:before{
    font-size:32px;
    content:"\e9b3"
}
.icon-32-document-bold:before{
    font-size:32px;
    content:"\e9b4"
}
.icon-32-document-mono:before{
    font-size:32px;
    content:"\e9b5"
}
.icon-32-download-bold:before{
    font-size:32px;
    content:"\e9b6"
}
.icon-32-download-mono:before{
    font-size:32px;
    content:"\e9b7"
}
.icon-32-edit-alternate-bold:before{
    font-size:32px;
    content:"\e9b8"
}
.icon-32-edit-alternate-mono:before{
    font-size:32px;
    content:"\e9b9"
}
.icon-32-edit-default-bold:before{
    font-size:32px;
    content:"\e9ba"
}
.icon-32-edit-default-mono:before{
    font-size:32px;
    content:"\e9bb"
}
.icon-32-eject-bold:before{
    font-size:32px;
    content:"\e9bc"
}
.icon-32-eject-mono:before{
    font-size:32px;
    content:"\e9bd"
}
.icon-32-electricity-bold:before{
    font-size:32px;
    content:"\e9be"
}
.icon-32-electricity-mono:before{
    font-size:32px;
    content:"\e9bf"
}
.icon-32-email-add-bold:before{
    font-size:32px;
    content:"\e9c0"
}
.icon-32-email-add-mono:before{
    font-size:32px;
    content:"\e9c1"
}
.icon-32-email-asterisk-bold:before{
    font-size:32px;
    content:"\e9c2"
}
.icon-32-email-asterisk-mono:before{
    font-size:32px;
    content:"\e9c3"
}
.icon-32-email-default-bold:before{
    font-size:32px;
    content:"\e9c4"
}
.icon-32-email-default-mono:before{
    font-size:32px;
    content:"\e9c5"
}
.icon-32-email-enter-bold:before{
    font-size:32px;
    content:"\e9c6"
}
.icon-32-email-enter-mono:before{
    font-size:32px;
    content:"\e9c7"
}
.icon-32-email-exit-bold:before{
    font-size:32px;
    content:"\e9c8"
}
.icon-32-email-exit-mono:before{
    font-size:32px;
    content:"\e9c9"
}
.icon-32-email-flag-bold:before{
    font-size:32px;
    content:"\e9ca"
}
.icon-32-email-flag-mono:before{
    font-size:32px;
    content:"\e9cb"
}
.icon-32-email-lock-bold:before{
    font-size:32px;
    content:"\e9cc"
}
.icon-32-email-lock-mono:before{
    font-size:32px;
    content:"\e9cd"
}
.icon-32-email-open-bold:before{
    font-size:32px;
    content:"\e9ce"
}
.icon-32-email-open-mono:before{
    font-size:32px;
    content:"\e9cf"
}
.icon-32-email-prohibited-bold:before{
    font-size:32px;
    content:"\e9d0"
}
.icon-32-email-prohibited-mono:before{
    font-size:32px;
    content:"\e9d1"
}
.icon-32-email-remove-bold:before{
    font-size:32px;
    content:"\e9d2"
}
.icon-32-email-remove-mono:before{
    font-size:32px;
    content:"\e9d3"
}
.icon-32-enlarge-bold:before{
    font-size:32px;
    content:"\e9d4"
}
.icon-32-enlarge-mono:before{
    font-size:32px;
    content:"\e9d5"
}
.icon-32-externalLink-bold:before{
    font-size:32px;
    content:"\e9d6"
}
.icon-32-externalLink-mono:before{
    font-size:32px;
    content:"\e9d7"
}
.icon-32-fastBackward-bold:before{
    font-size:32px;
    content:"\e9d8"
}
.icon-32-fastBackward-mono:before{
    font-size:32px;
    content:"\e9d9"
}
.icon-32-fastFoward-bold:before{
    font-size:32px;
    content:"\e9da"
}
.icon-32-fastFoward-mono:before{
    font-size:32px;
    content:"\e9db"
}
.icon-32-favorites-bold:before{
    font-size:32px;
    content:"\e9dc"
}
.icon-32-favorites-mono:before{
    font-size:32px;
    content:"\e9dd"
}
.icon-32-film-bold:before{
    font-size:32px;
    content:"\e9de"
}
.icon-32-film-mono:before{
    font-size:32px;
    content:"\e9df"
}
.icon-32-filter-bold:before{
    font-size:32px;
    content:"\e9e0"
}
.icon-32-filter-mono:before{
    font-size:32px;
    content:"\e9e1"
}
.icon-32-firstPage-bold:before{
    font-size:32px;
    content:"\e9e2"
}
.icon-32-firstPage-mono:before{
    font-size:32px;
    content:"\e9e3"
}
.icon-32-flag-bold:before{
    font-size:32px;
    content:"\e9e4"
}
.icon-32-flag-mono:before{
    font-size:32px;
    content:"\e9e5"
}
.icon-32-focus-bold:before{
    font-size:32px;
    content:"\e9e6"
}
.icon-32-focus-mono:before{
    font-size:32px;
    content:"\e9e7"
}
.icon-32-folder-add-bold:before{
    font-size:32px;
    content:"\e9e8"
}
.icon-32-folder-add-mono:before{
    font-size:32px;
    content:"\e9e9"
}
.icon-32-folder-closed-bold:before{
    font-size:32px;
    content:"\e9ea"
}
.icon-32-folder-closed-mono:before{
    font-size:32px;
    content:"\e9eb"
}
.icon-32-folder-flag-bold:before{
    font-size:32px;
    content:"\e9ec"
}
.icon-32-folder-flag-mono:before{
    font-size:32px;
    content:"\e9ed"
}
.icon-32-folder-lock-bold:before{
    font-size:32px;
    content:"\e9ee"
}
.icon-32-folder-lock-mono:before{
    font-size:32px;
    content:"\e9ef"
}
.icon-32-folder-open-bold:before{
    font-size:32px;
    content:"\e9f0"
}
.icon-32-folder-open-mono:before{
    font-size:32px;
    content:"\e9f1"
}
.icon-32-folder-remove-bold:before{
    font-size:32px;
    content:"\e9f2"
}
.icon-32-folder-remove-mono:before{
    font-size:32px;
    content:"\e9f3"
}
.icon-32-foward-bold:before{
    font-size:32px;
    content:"\e9f4"
}
.icon-32-foward-mono:before{
    font-size:32px;
    content:"\e9f5"
}
.icon-32-funnel-bold:before{
    font-size:32px;
    content:"\e9f6"
}
.icon-32-funnel-mono:before{
    font-size:32px;
    content:"\e9f7"
}
.icon-32-gender-female-bold:before{
    font-size:32px;
    content:"\e9f8"
}
.icon-32-gender-female-mono:before{
    font-size:32px;
    content:"\e9f9"
}
.icon-32-gender-male-bold:before{
    font-size:32px;
    content:"\e9fa"
}
.icon-32-gender-male-mono:before{
    font-size:32px;
    content:"\e9fb"
}
.icon-32-gift-bold:before{
    font-size:32px;
    content:"\e9fc"
}
.icon-32-gift-mono:before{
    font-size:32px;
    content:"\e9fd"
}
.icon-32-global-mono:before{
    font-size:32px;
    content:"\e9fe"
}
.icon-32-green-bold:before{
    font-size:32px;
    content:"\e9ff"
}
.icon-32-green-mono:before{
    font-size:32px;
    content:"\ea00"
}
.icon-32-gridView-bold:before{
    font-size:32px;
    content:"\ea01"
}
.icon-32-gridView-mono:before{
    font-size:32px;
    content:"\ea02"
}
.icon-32-hamburgerMenu-bold:before{
    font-size:32px;
    content:"\ea03"
}
.icon-32-hamburgerMenu-mono:before{
    font-size:32px;
    content:"\ea04"
}
.icon-32-hand-down-mono:before{
    font-size:32px;
    content:"\ea05"
}
.icon-32-hand-left-mono:before{
    font-size:32px;
    content:"\ea06"
}
.icon-32-hand-right-mono:before{
    font-size:32px;
    content:"\ea07"
}
.icon-32-hand-up-mono:before{
    font-size:32px;
    content:"\ea08"
}
.icon-32-home-bold:before{
    font-size:32px;
    content:"\ea09"
}
.icon-32-home-mono:before{
    font-size:32px;
    content:"\ea0a"
}
.icon-32-hot-bold:before{
    font-size:32px;
    content:"\ea0b"
}
.icon-32-hot-mono:before{
    font-size:32px;
    content:"\ea0c"
}
.icon-32-image-bold:before{
    font-size:32px;
    content:"\ea0d"
}
.icon-32-image-mono:before{
    font-size:32px;
    content:"\ea0e"
}
.icon-32-info-bold:before{
    font-size:32px;
    content:"\ea0f"
}
.icon-32-info-mono:before{
    font-size:32px;
    content:"\ea10"
}
.icon-32-iPhone-bold:before{
    font-size:32px;
    content:"\ea11"
}
.icon-32-iPhone-mono:before{
    font-size:32px;
    content:"\ea12"
}
.icon-32-justify-bold:before{
    font-size:32px;
    content:"\ea13"
}
.icon-32-justify-mono:before{
    font-size:32px;
    content:"\ea14"
}
.icon-32-key-bold:before{
    font-size:32px;
    content:"\ea15"
}
.icon-32-key-mono:before{
    font-size:32px;
    content:"\ea16"
}
.icon-32-lastPage-bold:before{
    font-size:32px;
    content:"\ea17"
}
.icon-32-lastPage-mono:before{
    font-size:32px;
    content:"\ea18"
}
.icon-32-lightbulb-bold:before{
    font-size:32px;
    content:"\ea19"
}
.icon-32-lightbulb-mono:before{
    font-size:32px;
    content:"\ea1a"
}
.icon-32-link-bold:before{
    font-size:32px;
    content:"\ea1b"
}
.icon-32-link-mono:before{
    font-size:32px;
    content:"\ea1c"
}
.icon-32-location-bold:before{
    font-size:32px;
    content:"\ea1d"
}
.icon-32-location-mono:before{
    font-size:32px;
    content:"\ea1e"
}
.icon-32-lock-closed-bold:before{
    font-size:32px;
    content:"\ea1f"
}
.icon-32-lock-closed-mono:before{
    font-size:32px;
    content:"\ea20"
}
.icon-32-lock-open-bold:before{
    font-size:32px;
    content:"\ea21"
}
.icon-32-lock-open-mono:before{
    font-size:32px;
    content:"\ea22"
}
.icon-32-logBook-bold:before{
    font-size:32px;
    content:"\ea23"
}
.icon-32-logBook-mono:before{
    font-size:32px;
    content:"\ea24"
}
.icon-32-magnetize-bold:before{
    font-size:32px;
    content:"\ea25"
}
.icon-32-magnetize-mono:before{
    font-size:32px;
    content:"\ea26"
}
.icon-32-measure-bold:before{
    font-size:32px;
    content:"\ea27"
}
.icon-32-measure-mono:before{
    font-size:32px;
    content:"\ea28"
}
.icon-32-minus-bold:before{
    font-size:32px;
    content:"\ea29"
}
.icon-32-minus-mono:before{
    font-size:32px;
    content:"\ea2a"
}
.icon-32-money-canadianDollar-bold:before{
    font-size:32px;
    content:"\ea2b"
}
.icon-32-money-canadianDollar-mono:before{
    font-size:32px;
    content:"\ea2c"
}
.icon-32-money-dollar-bold:before{
    font-size:32px;
    content:"\ea2d"
}
.icon-32-money-dollar-mono:before{
    font-size:32px;
    content:"\ea2e"
}
.icon-32-money-euro-bold:before{
    font-size:32px;
    content:"\ea2f"
}
.icon-32-money-euro-mono:before{
    font-size:32px;
    content:"\ea30"
}
.icon-32-money-pound-bold:before{
    font-size:32px;
    content:"\ea31"
}
.icon-32-money-pound-mono:before{
    font-size:32px;
    content:"\ea32"
}
.icon-32-monitor-bold:before{
    font-size:32px;
    content:"\ea33"
}
.icon-32-monitor-mono:before{
    font-size:32px;
    content:"\ea34"
}
.icon-32-moreHori-bold:before{
    font-size:32px;
    content:"\ea35"
}
.icon-32-moreHori-mono:before{
    font-size:32px;
    content:"\ea36"
}
.icon-32-moreVert-bold:before{
    font-size:32px;
    content:"\ea37"
}
.icon-32-moreVert-mono:before{
    font-size:32px;
    content:"\ea38"
}
.icon-32-moreWindows-bold:before{
    font-size:32px;
    content:"\ea39"
}
.icon-32-moreWindows-mono:before{
    font-size:32px;
    content:"\ea3a"
}
.icon-32-move-bold:before{
    font-size:32px;
    content:"\ea3b"
}
.icon-32-move-mono:before{
    font-size:32px;
    content:"\ea3c"
}
.icon-32-multiInstrument-bold:before{
    font-size:32px;
    content:"\ea3d"
}
.icon-32-multiInstrument-mono:before{
    font-size:32px;
    content:"\ea3e"
}
.icon-32-newWindow-bold:before{
    font-size:32px;
    content:"\ea3f"
}
.icon-32-newWindow-mono:before{
    font-size:32px;
    content:"\ea40"
}
.icon-32-note-bold:before{
    font-size:32px;
    content:"\ea41"
}
.icon-32-note-mono:before{
    font-size:32px;
    content:"\ea42"
}
.icon-32-pause-bold:before{
    font-size:32px;
    content:"\ea43"
}
.icon-32-pause-mono:before{
    font-size:32px;
    content:"\ea44"
}
.icon-32-phone-bold:before{
    font-size:32px;
    content:"\ea45"
}
.icon-32-phone-mono:before{
    font-size:32px;
    content:"\ea46"
}
.icon-32-phoneVolume-bold:before{
    font-size:32px;
    content:"\ea47"
}
.icon-32-phoneVolume-mono:before{
    font-size:32px;
    content:"\ea48"
}
.icon-32-pieChart-bold:before{
    font-size:32px;
    content:"\ea49"
}
.icon-32-pieChart-mono:before{
    font-size:32px;
    content:"\ea4a"
}
.icon-32-pin-bold:before{
    font-size:32px;
    content:"\ea4b"
}
.icon-32-pin-mono:before{
    font-size:32px;
    content:"\ea4c"
}
.icon-32-play-bold:before{
    font-size:32px;
    content:"\ea4d"
}
.icon-32-play-mono:before{
    font-size:32px;
    content:"\ea4e"
}
.icon-32-playButton-bold:before{
    font-size:32px;
    content:"\ea4f"
}
.icon-32-playButton-mono:before{
    font-size:32px;
    content:"\ea50"
}
.icon-32-plus-bold:before{
    font-size:32px;
    content:"\ea51"
}
.icon-32-plus-mono:before{
    font-size:32px;
    content:"\ea52"
}
.icon-32-power-bold:before{
    font-size:32px;
    content:"\ea53"
}
.icon-32-power-mono:before{
    font-size:32px;
    content:"\ea54"
}
.icon-32-print-bold:before{
    font-size:32px;
    content:"\ea55"
}
.icon-32-print-mono:before{
    font-size:32px;
    content:"\ea56"
}
.icon-32-profile-bold:before{
    font-size:32px;
    content:"\ea57"
}
.icon-32-profile-mono:before{
    font-size:32px;
    content:"\ea58"
}
.icon-32-profileAdd-bold:before{
    font-size:32px;
    content:"\ea59"
}
.icon-32-profileAdd-mono:before{
    font-size:32px;
    content:"\ea5a"
}
.icon-32-profileDelete-bold:before{
    font-size:32px;
    content:"\ea5b"
}
.icon-32-profileDelete-mono:before{
    font-size:32px;
    content:"\ea5c"
}
.icon-32-prohibited-bold:before{
    font-size:32px;
    content:"\ea5d"
}
.icon-32-prohibited-mono:before{
    font-size:32px;
    content:"\ea5e"
}
.icon-32-promotions-bold:before{
    font-size:32px;
    content:"\ea5f"
}
.icon-32-promotions-mono:before{
    font-size:32px;
    content:"\ea60"
}
.icon-32-pushPin-bold:before{
    font-size:32px;
    content:"\ea61"
}
.icon-32-pushPin-mono:before{
    font-size:32px;
    content:"\ea62"
}
.icon-32-qr-bold:before{
    font-size:32px;
    content:"\ea63"
}
.icon-32-qr-mono:before{
    font-size:32px;
    content:"\ea64"
}
.icon-32-quatationClose-bold:before{
    font-size:32px;
    content:"\ea65"
}
.icon-32-quatationOpen-bold:before{
    font-size:32px;
    content:"\ea66"
}
.icon-32-question-bold:before{
    font-size:32px;
    content:"\ea67"
}
.icon-32-question-mono:before{
    font-size:32px;
    content:"\ea68"
}
.icon-32-random-bold:before{
    font-size:32px;
    content:"\ea69"
}
.icon-32-random-mono:before{
    font-size:32px;
    content:"\ea6a"
}
.icon-32-rating-bold:before{
    font-size:32px;
    content:"\ea6b"
}
.icon-32-rating-mono:before{
    font-size:32px;
    content:"\ea6c"
}
.icon-32-recycle-bold:before{
    font-size:32px;
    content:"\ea6d"
}
.icon-32-recycle-mono:before{
    font-size:32px;
    content:"\ea6e"
}
.icon-32-refresh-bold:before{
    font-size:32px;
    content:"\ea6f"
}
.icon-32-refresh-mono:before{
    font-size:32px;
    content:"\ea70"
}
.icon-32-repeat-bold:before{
    font-size:32px;
    content:"\ea71"
}
.icon-32-repeat-mono:before{
    font-size:32px;
    content:"\ea72"
}
.icon-32-resizeLarge-bold:before{
    font-size:32px;
    content:"\ea73"
}
.icon-32-resizeLarge-mono:before{
    font-size:32px;
    content:"\ea74"
}
.icon-32-resizeSmall-bold:before{
    font-size:32px;
    content:"\ea75"
}
.icon-32-resource-bold:before{
    font-size:32px;
    content:"\ea76"
}
.icon-32-resizeSmall-mono:before{
    font-size:32px;
    content:"\ea77"
}
.icon-32-resource-mono:before{
    font-size:32px;
    content:"\ea78"
}
.icon-32-restart-bold:before{
    font-size:32px;
    content:"\ea79"
}
.icon-32-restart-mono:before{
    font-size:32px;
    content:"\ea7a"
}
.icon-32-return-bold:before{
    font-size:32px;
    content:"\ea7b"
}
.icon-32-return-mono:before{
    font-size:32px;
    content:"\ea7c"
}
.icon-32-reverseLeft-bold:before{
    font-size:32px;
    content:"\ea7d"
}
.icon-32-reverseLeft-mono:before{
    font-size:32px;
    content:"\ea7e"
}
.icon-32-reverseRight-bold:before{
    font-size:32px;
    content:"\ea7f"
}
.icon-32-reverseRight-mono:before{
    font-size:32px;
    content:"\ea80"
}
.icon-32-rewind-bold:before{
    font-size:32px;
    content:"\ea81"
}
.icon-32-rewind-mono:before{
    font-size:32px;
    content:"\ea82"
}
.icon-32-search-bold:before{
    font-size:32px;
    content:"\ea83"
}
.icon-32-search-mono:before{
    font-size:32px;
    content:"\ea84"
}
.icon-32-settings-alt-bold:before{
    font-size:32px;
    content:"\ea85"
}
.icon-32-settings-alt-mono:before{
    font-size:32px;
    content:"\ea86"
}
.icon-32-settings-bold:before{
    font-size:32px;
    content:"\ea87"
}
.icon-32-settings-mono:before{
    font-size:32px;
    content:"\ea88"
}
.icon-32-share-alt-bold:before{
    font-size:32px;
    content:"\ea89"
}
.icon-32-share-alt-mono:before{
    font-size:32px;
    content:"\ea8a"
}
.icon-32-share-bold:before{
    font-size:32px;
    content:"\ea8b"
}
.icon-32-share-mono:before{
    font-size:32px;
    content:"\ea8c"
}
.icon-32-shipping-bold:before{
    font-size:32px;
    content:"\ea8d"
}
.icon-32-shipping-mono:before{
    font-size:32px;
    content:"\ea8e"
}
.icon-32-signal-bold:before{
    font-size:32px;
    content:"\ea8f"
}
.icon-32-signal-mono:before{
    font-size:32px;
    content:"\ea90"
}
.icon-32-sleep-bold:before{
    font-size:32px;
    content:"\ea91"
}
.icon-32-sleep-mono:before{
    font-size:32px;
    content:"\ea92"
}
.icon-32-sortArrows-bold:before{
    font-size:32px;
    content:"\ea93"
}
.icon-32-sortArrows-mono:before{
    font-size:32px;
    content:"\ea94"
}
.icon-32-speaker-1-bold:before{
    font-size:32px;
    content:"\ea95"
}
.icon-32-speaker-1-mono:before{
    font-size:32px;
    content:"\ea96"
}
.icon-32-speaker-2-bold:before{
    font-size:32px;
    content:"\ea97"
}
.icon-32-speaker-2-mono:before{
    font-size:32px;
    content:"\ea98"
}
.icon-32-speaker-full-bold:before{
    font-size:32px;
    content:"\ea99"
}
.icon-32-speaker-full-mono:before{
    font-size:32px;
    content:"\ea9a"
}
.icon-32-speaker-off-bold:before{
    font-size:32px;
    content:"\ea9b"
}
.icon-32-speaker-off-mono:before{
    font-size:32px;
    content:"\ea9c"
}
.icon-32-stop-bold:before{
    font-size:32px;
    content:"\ea9d"
}
.icon-32-stop-mono:before{
    font-size:32px;
    content:"\ea9e"
}
.icon-32-stopwatch-bold:before{
    font-size:32px;
    content:"\ea9f"
}
.icon-32-stopwatch-mono:before{
    font-size:32px;
    content:"\eaa0"
}
.icon-32-success-bold:before{
    font-size:32px;
    content:"\eaa1"
}
.icon-32-success-mono:before{
    font-size:32px;
    content:"\eaa2"
}
.icon-32-support-bold:before{
    font-size:32px;
    content:"\eaa3"
}
.icon-32-support-mono:before{
    font-size:32px;
    content:"\eaa4"
}
.icon-32-table-bold:before{
    font-size:32px;
    content:"\eaa5"
}
.icon-32-table-mono:before{
    font-size:32px;
    content:"\eaa6"
}
.icon-32-tag-bold:before{
    font-size:32px;
    content:"\eaa7"
}
.icon-32-tag-mono:before{
    font-size:32px;
    content:"\eaa8"
}
.icon-32-techDocumentation-bold:before{
    font-size:32px;
    content:"\eaa9"
}
.icon-32-techDocumentation-mono:before{
    font-size:32px;
    content:"\eaaa"
}
.icon-32-thumbsDowon-bold:before{
    font-size:32px;
    content:"\eaab"
}
.icon-32-thumbsDowon-mono:before{
    font-size:32px;
    content:"\eaac"
}
.icon-32-thumbsUp-bold:before{
    font-size:32px;
    content:"\eaad"
}
.icon-32-thumbsUp-mono:before{
    font-size:32px;
    content:"\eaae"
}
.icon-32-tint-bold:before{
    font-size:32px;
    content:"\eaaf"
}
.icon-32-tint-mono:before{
    font-size:32px;
    content:"\eab0"
}
.icon-32-unreturn-bold:before{
    font-size:32px;
    content:"\eab1"
}
.icon-32-unreturn-mono:before{
    font-size:32px;
    content:"\eab2"
}
.icon-32-unshare-bold:before{
    font-size:32px;
    content:"\eab3"
}
.icon-32-unshare-mono:before{
    font-size:32px;
    content:"\eab4"
}
.icon-32-up-arrow-bold-copy .path1:before{
    font-size:32px;
    content:"\eab5";
    color:#222
}
.icon-32-up-arrow-bold-copy .path2:before{
    font-size:32px;
    content:"\eab6";
    margin-left:-1em;
    color:#598db5
}
.icon-32-up-arrow-bold-copy .path3:before{
    font-size:32px;
    content:"\eab7";
    margin-left:-1em;
    color:#fff46d
}
.icon-32-up-arrow-bold-copy .path4:before{
    font-size:32px;
    content:"\eab8";
    margin-left:-1em;
    color:#ed1c24
}
.icon-32-up-arrow-bold-copy .path5:before{
    font-size:32px;
    content:"\eab9";
    margin-left:-1em;
    color:#fff
}
.icon-32-upload-bold:before{
    font-size:32px;
    content:"\eaba"
}
.icon-32-upload-mono:before{
    font-size:32px;
    content:"\eabb"
}
.icon-32-vcard-bold:before{
    font-size:32px;
    content:"\eabc"
}
.icon-32-vcard-mono:before{
    font-size:32px;
    content:"\eabd"
}
.icon-32-video-bold:before{
    font-size:32px;
    content:"\eabe"
}
.icon-32-video-mono:before{
    font-size:32px;
    content:"\eabf"
}
.icon-32-view-off-bold:before{
    font-size:32px;
    content:"\eac0"
}
.icon-32-view-off-mono:before{
    font-size:32px;
    content:"\eac1"
}
.icon-32-view-on-bold:before{
    font-size:32px;
    content:"\eac2"
}
.icon-32-view-on-mono:before{
    font-size:32px;
    content:"\eac3"
}
.icon-32-warning-bold:before{
    font-size:32px;
    content:"\eac4"
}
.icon-32-warning-mono:before{
    font-size:32px;
    content:"\eac5"
}
.icon-32-webinar-bold:before{
    font-size:32px;
    content:"\eac6"
}
.icon-32-webinar-mono:before{
    font-size:32px;
    content:"\eac7"
}
.icon-32-wireless-alt-bold:before{
    font-size:32px;
    content:"\eac8"
}
.icon-32-wireless-bold:before{
    font-size:32px;
    content:"\eac9"
}
.icon-32-wireless-mono:before{
    font-size:32px;
    content:"\eaca"
}
.icon-32-zoomIn-bold:before{
    font-size:32px;
    content:"\eacb"
}
.icon-32-zoomIn-mono:before{
    font-size:32px;
    content:"\eacc"
}
.icon-32-zoomOut-bold:before{
    font-size:32px;
    content:"\eacd"
}
.icon-32-zoomOut-mono:before{
    font-size:32px;
    content:"\eace"
}
.icon-24-addressBook-bold:before{
    font-size:24px;
    content:"\eacf"
}
.icon-24-addressBook-mono:before{
    font-size:24px;
    content:"\ead0"
}
.icon-24-alert-bold:before{
    font-size:24px;
    content:"\ead1"
}
.icon-24-alert-mono:before{
    font-size:24px;
    content:"\ead2"
}
.icon-24-align-center-bold:before{
    font-size:24px;
    content:"\ead3"
}
.icon-24-align-center-mono:before{
    font-size:24px;
    content:"\ead4"
}
.icon-24-align-left-bold:before{
    font-size:24px;
    content:"\ead5"
}
.icon-24-align-left-mono:before{
    font-size:24px;
    content:"\ead6"
}
.icon-24-align-right-bold:before{
    font-size:24px;
    content:"\ead7"
}
.icon-24-align-right-mono:before{
    font-size:24px;
    content:"\ead8"
}
.icon-24-analyze-bold:before{
    font-size:24px;
    content:"\ead9"
}
.icon-24-analyze-mono:before{
    font-size:24px;
    content:"\eada"
}
.icon-24-arrow-down-bold:before{
    font-size:24px;
    content:"\eadb"
}
.icon-24-arrow-down-mono:before{
    font-size:24px;
    content:"\eadc"
}
.icon-24-arrow-left-bold:before{
    font-size:24px;
    content:"\eadd"
}
.icon-24-arrow-left-mono:before{
    font-size:24px;
    content:"\eade"
}
.icon-24-arrow-right-bold:before{
    font-size:24px;
    content:"\eadf"
}
.icon-24-arrow-right-mono:before{
    font-size:24px;
    content:"\eae0"
}
.icon-24-arrow-up-bold:before{
    font-size:24px;
    content:"\eae1"
}
.icon-24-arrow-up-mono:before{
    font-size:24px;
    content:"\eae2"
}
.icon-24-asterisk-bold:before{
    font-size:24px;
    content:"\eae3"
}
.icon-24-asterisk-mono:before{
    font-size:24px;
    content:"\eae4"
}
.icon-24-attachment-mono:before{
    font-size:24px;
    content:"\eae5"
}
.icon-24-awake-bold:before{
    font-size:24px;
    content:"\eae6"
}
.icon-24-awake-mono:before{
    font-size:24px;
    content:"\eae7"
}
.icon-24-award-bold:before{
    font-size:24px;
    content:"\eae8"
}
.icon-24-award-mono:before{
    font-size:24px;
    content:"\eae9"
}
.icon-24-barcode-bold:before{
    font-size:24px;
    content:"\eaea"
}
.icon-24-barcode-mono:before{
    font-size:24px;
    content:"\eaeb"
}
.icon-24-bell-bold:before{
    font-size:24px;
    content:"\eaec"
}
.icon-24-bell-mono:before{
    font-size:24px;
    content:"\eaed"
}
.icon-24-bin-bold:before{
    font-size:24px;
    content:"\eaee"
}
.icon-24-bin-mono:before{
    font-size:24px;
    content:"\eaef"
}
.icon-24-bluetooth-mono:before{
    font-size:24px;
    content:"\eaf0"
}
.icon-24-bookmark-bold:before{
    font-size:24px;
    content:"\eaf1"
}
.icon-24-bookmark-mono:before{
    font-size:24px;
    content:"\eaf2"
}
.icon-24-bulletedList-bold:before{
    font-size:24px;
    content:"\eaf3"
}
.icon-24-bulletedList-mono:before{
    font-size:24px;
    content:"\eaf4"
}
.icon-24-caculator-bold:before{
    font-size:24px;
    content:"\eaf5"
}
.icon-24-caculator-mono:before{
    font-size:24px;
    content:"\eaf6"
}
.icon-24-calendar-bold:before{
    font-size:24px;
    content:"\eaf7"
}
.icon-24-calendar-mono:before{
    font-size:24px;
    content:"\eaf8"
}
.icon-24-camera-bold:before{
    font-size:24px;
    content:"\eaf9"
}
.icon-24-camera-mono:before{
    font-size:24px;
    content:"\eafa"
}
.icon-24-cart-add-bold:before{
    font-size:24px;
    content:"\eafb"
}
.icon-24-cart-add-mono:before{
    font-size:24px;
    content:"\eafc"
}
.icon-24-cart-default-bold:before{
    font-size:24px;
    content:"\eafd"
}
.icon-24-cart-default-mono:before{
    font-size:24px;
    content:"\eafe"
}
.icon-24-cart-remove-bold:before{
    font-size:24px;
    content:"\eaff"
}
.icon-24-cart-remove-mono:before{
    font-size:24px;
    content:"\eb00"
}
.icon-24-catalog-bold:before{
    font-size:24px;
    content:"\eb01"
}
.icon-24-catalog-mono:before{
    font-size:24px;
    content:"\eb02"
}
.icon-24-chat-bold:before{
    font-size:24px;
    content:"\eb03"
}
.icon-24-chat-mono:before{
    font-size:24px;
    content:"\eb04"
}
.icon-24-checkedBox-bold:before{
    font-size:24px;
    content:"\eb05"
}
.icon-24-checkedBox-mono:before{
    font-size:24px;
    content:"\eb06"
}
.icon-24-checkmark-bold:before{
    font-size:24px;
    content:"\eb07"
}
.icon-24-checkmark-mono:before{
    font-size:24px;
    content:"\eb08"
}
.icon-24-chevron-down-bold:before{
    font-size:24px;
    content:"\eb09"
}
.icon-24-chevron-down-mono:before{
    font-size:24px;
    content:"\eb0a"
}
.icon-24-chevron-left-bold:before{
    font-size:24px;
    content:"\eb0b"
}
.icon-24-chevron-left-mono:before{
    font-size:24px;
    content:"\eb0c"
}
.icon-24-chevron-right-bold:before{
    font-size:24px;
    content:"\eb0d"
}
.icon-24-chevron-right-mono:before{
    font-size:24px;
    content:"\eb0e"
}
.icon-24-chevron-up-bold:before{
    font-size:24px;
    content:"\eb0f"
}
.icon-24-chevron-up-mono:before{
    font-size:24px;
    content:"\eb10"
}
.icon-24-clipboard-bold:before{
    font-size:24px;
    content:"\eb11"
}
.icon-24-clipboard-mono:before{
    font-size:24px;
    content:"\eb12"
}
.icon-24-clock-bold:before{
    font-size:24px;
    content:"\eb13"
}
.icon-24-clock-mono:before{
    font-size:24px;
    content:"\eb14"
}
.icon-24-close-bold:before{
    font-size:24px;
    content:"\eb15"
}
.icon-24-close-mono:before{
    font-size:24px;
    content:"\eb16"
}
.icon-24-cloud-bold:before{
    font-size:24px;
    content:"\eb17"
}
.icon-24-cloud-mono:before{
    font-size:24px;
    content:"\eb18"
}
.icon-24-cloudDownload-bold:before{
    font-size:24px;
    content:"\eb19"
}
.icon-24-cloudDownload-mono:before{
    font-size:24px;
    content:"\eb1a"
}
.icon-24-cloudUpload-bold:before{
    font-size:24px;
    content:"\eb1b"
}
.icon-24-cloudUpload-mono:before{
    font-size:24px;
    content:"\eb1c"
}
.icon-24-cold-bold:before{
    font-size:24px;
    content:"\eb1d"
}
.icon-24-cold-mono:before{
    font-size:24px;
    content:"\eb1e"
}
.icon-24-comment-bold:before{
    font-size:24px;
    content:"\eb1f"
}
.icon-24-comment-mono:before{
    font-size:24px;
    content:"\eb20"
}
.icon-24-community-bold:before{
    font-size:24px;
    content:"\eb21"
}
.icon-24-community-mono:before{
    font-size:24px;
    content:"\eb22"
}
.icon-24-compass-bold:before{
    font-size:24px;
    content:"\eb23"
}
.icon-24-compass-mono:before{
    font-size:24px;
    content:"\eb24"
}
.icon-24-contrast-bold:before{
    font-size:24px;
    content:"\eb25"
}
.icon-24-contrast-mono:before{
    font-size:24px;
    content:"\eb26"
}
.icon-24-creditCard-bold:before{
    font-size:24px;
    content:"\eb27"
}
.icon-24-creditCard-mono:before{
    font-size:24px;
    content:"\eb28"
}
.icon-24-crop-bold:before{
    font-size:24px;
    content:"\eb29"
}
.icon-24-crop-mono:before{
    font-size:24px;
    content:"\eb2a"
}
.icon-24-customerApproved-bold:before{
    font-size:24px;
    content:"\eb2b"
}
.icon-24-customerApproved-mono:before{
    font-size:24px;
    content:"\eb2c"
}
.icon-24-cut-bold:before{
    font-size:24px;
    content:"\eb2d"
}
.icon-24-cut-mono:before{
    font-size:24px;
    content:"\eb2e"
}
.icon-24-dashboard-bold:before{
    font-size:24px;
    content:"\eb2f"
}
.icon-24-dashboard-mono:before{
    font-size:24px;
    content:"\eb30"
}
.icon-24-data-bold:before{
    font-size:24px;
    content:"\eb31"
}
.icon-24-data-mono:before{
    font-size:24px;
    content:"\eb32"
}
.icon-24-database-ban-bold:before{
    font-size:24px;
    content:"\eb33"
}
.icon-24-database-ban-mono:before{
    font-size:24px;
    content:"\eb34"
}
.icon-24-database-default-bold:before{
    font-size:24px;
    content:"\eb35"
}
.icon-24-database-default-mono:before{
    font-size:24px;
    content:"\eb36"
}
.icon-24-database-lock-bold:before{
    font-size:24px;
    content:"\eb37"
}
.icon-24-database-lock-mono:before{
    font-size:24px;
    content:"\eb38"
}
.icon-24-database-minus-bold:before{
    font-size:24px;
    content:"\eb39"
}
.icon-24-database-minus-mono:before{
    font-size:24px;
    content:"\eb3a"
}
.icon-24-database-plus-bold:before{
    font-size:24px;
    content:"\eb3b"
}
.icon-24-database-plus-mono:before{
    font-size:24px;
    content:"\eb3c"
}
.icon-24-delete-bold:before{
    font-size:24px;
    content:"\eb3d"
}
.icon-24-delete-mono:before{
    font-size:24px;
    content:"\eb3e"
}
.icon-24-direction-bold:before{
    font-size:24px;
    content:"\eb3f"
}
.icon-24-direction-mono:before{
    font-size:24px;
    content:"\eb40"
}
.icon-24-divide-bold:before{
    font-size:24px;
    content:"\eb41"
}
.icon-24-divide-mono:before{
    font-size:24px;
    content:"\eb42"
}
.icon-24-docs-7z-bold:before{
    font-size:24px;
    content:"\eb43"
}
.icon-24-docs-7z-mono:before{
    font-size:24px;
    content:"\eb44"
}
.icon-24-docs-cat-bold:before{
    font-size:24px;
    content:"\eb45"
}
.icon-24-docs-cat-mono:before{
    font-size:24px;
    content:"\eb46"
}
.icon-24-docs-css-bold:before{
    font-size:24px;
    content:"\eb47"
}
.icon-24-docs-css-mono:before{
    font-size:24px;
    content:"\eb48"
}
.icon-24-docs-csv-bold:before{
    font-size:24px;
    content:"\eb49"
}
.icon-24-docs-csv-mono:before{
    font-size:24px;
    content:"\eb4a"
}
.icon-24-docs-doc-bold:before{
    font-size:24px;
    content:"\eb4b"
}
.icon-24-docs-doc-mono:before{
    font-size:24px;
    content:"\eb4c"
}
.icon-24-docs-eps-bold:before{
    font-size:24px;
    content:"\eb4d"
}
.icon-24-docs-eps-mono:before{
    font-size:24px;
    content:"\eb4e"
}
.icon-24-docs-exe-bold:before{
    font-size:24px;
    content:"\eb4f"
}
.icon-24-docs-exe-mono:before{
    font-size:24px;
    content:"\eb50"
}
.icon-24-docs-gif-bold:before{
    font-size:24px;
    content:"\eb51"
}
.icon-24-docs-gif-mono:before{
    font-size:24px;
    content:"\eb52"
}
.icon-24-docs-gz-bold:before{
    font-size:24px;
    content:"\eb53"
}
.icon-24-docs-gz-mono:before{
    font-size:24px;
    content:"\eb54"
}
.icon-24-docs-ics-bold:before{
    font-size:24px;
    content:"\eb55"
}
.icon-24-docs-ics-mono:before{
    font-size:24px;
    content:"\eb56"
}
.icon-24-docs-img-bold:before{
    font-size:24px;
    content:"\eb57"
}
.icon-24-docs-img-mono:before{
    font-size:24px;
    content:"\eb58"
}
.icon-24-docs-jpg-bold:before{
    font-size:24px;
    content:"\eb59"
}
.icon-24-docs-jpg-mono:before{
    font-size:24px;
    content:"\eb5a"
}
.icon-24-docs-m4v-bold:before{
    font-size:24px;
    content:"\eb5b"
}
.icon-24-docs-m4v-mono:before{
    font-size:24px;
    content:"\eb5c"
}
.icon-24-docs-mp3-bold:before{
    font-size:24px;
    content:"\eb5d"
}
.icon-24-docs-mp3-mono:before{
    font-size:24px;
    content:"\eb5e"
}
.icon-24-docs-mp4-bold:before{
    font-size:24px;
    content:"\eb5f"
}
.icon-24-docs-mp4-mono:before{
    font-size:24px;
    content:"\eb60"
}
.icon-24-docs-pdf-bold:before{
    font-size:24px;
    content:"\eb61"
}
.icon-24-docs-pdf-mono:before{
    font-size:24px;
    content:"\eb62"
}
.icon-24-docs-png-bold:before{
    font-size:24px;
    content:"\eb63"
}
.icon-24-docs-png-mono:before{
    font-size:24px;
    content:"\eb64"
}
.icon-24-docs-pps-bold:before{
    font-size:24px;
    content:"\eb65"
}
.icon-24-docs-pps-mono:before{
    font-size:24px;
    content:"\eb66"
}
.icon-24-docs-ppt-bold:before{
    font-size:24px;
    content:"\eb67"
}
.icon-24-docs-ppt-mono:before{
    font-size:24px;
    content:"\eb68"
}
.icon-24-docs-psd-bold:before{
    font-size:24px;
    content:"\eb69"
}
.icon-24-docs-psd-mono:before{
    font-size:24px;
    content:"\eb6a"
}
.icon-24-docs-rar-bold:before{
    font-size:24px;
    content:"\eb6b"
}
.icon-24-docs-rar-mono:before{
    font-size:24px;
    content:"\eb6c"
}
.icon-24-docs-swf-bold:before{
    font-size:24px;
    content:"\eb6d"
}
.icon-24-docs-swf-mono:before{
    font-size:24px;
    content:"\eb6e"
}
.icon-24-docs-tar-bold:before{
    font-size:24px;
    content:"\eb6f"
}
.icon-24-docs-tar-mono:before{
    font-size:24px;
    content:"\eb70"
}
.icon-24-docs-tgz-bold:before{
    font-size:24px;
    content:"\eb71"
}
.icon-24-docs-tgz-mono:before{
    font-size:24px;
    content:"\eb72"
}
.icon-24-docs-tif-bold:before{
    font-size:24px;
    content:"\eb73"
}
.icon-24-docs-tif-mono:before{
    font-size:24px;
    content:"\eb74"
}
.icon-24-docs-txt-bold:before{
    font-size:24px;
    content:"\eb75"
}
.icon-24-docs-txt-mono:before{
    font-size:24px;
    content:"\eb76"
}
.icon-24-docs-vcf-bold:before{
    font-size:24px;
    content:"\eb77"
}
.icon-24-docs-vcf-mono:before{
    font-size:24px;
    content:"\eb78"
}
.icon-24-docs-wmf-bold:before{
    font-size:24px;
    content:"\eb79"
}
.icon-24-docs-wmf-mono:before{
    font-size:24px;
    content:"\eb7a"
}
.icon-24-docs-wmv-bold:before{
    font-size:24px;
    content:"\eb7b"
}
.icon-24-docs-wmv-mono:before{
    font-size:24px;
    content:"\eb7c"
}
.icon-24-docs-xls-bold:before{
    font-size:24px;
    content:"\eb7d"
}
.icon-24-docs-xls-mono:before{
    font-size:24px;
    content:"\eb7e"
}
.icon-24-docs-xml-bold:before{
    font-size:24px;
    content:"\eb7f"
}
.icon-24-docs-xml-mono:before{
    font-size:24px;
    content:"\eb80"
}
.icon-24-docs-zip-bold:before{
    font-size:24px;
    content:"\eb81"
}
.icon-24-docs-zip-mono:before{
    font-size:24px;
    content:"\eb82"
}
.icon-24-document-bold:before{
    font-size:24px;
    content:"\eb83"
}
.icon-24-document-mono:before{
    font-size:24px;
    content:"\eb84"
}
.icon-24-download-bold:before{
    font-size:24px;
    content:"\eb85"
}
.icon-24-download-mono:before{
    font-size:24px;
    content:"\eb86"
}
.icon-24-edit-alternate-bold:before{
    font-size:24px;
    content:"\eb87"
}
.icon-24-edit-alternate-mono:before{
    font-size:24px;
    content:"\eb88"
}
.icon-24-edit-default-bold:before{
    font-size:24px;
    content:"\eb89"
}
.icon-24-edit-default-mono:before{
    font-size:24px;
    content:"\eb8a"
}
.icon-24-eject-bold:before{
    font-size:24px;
    content:"\eb8b"
}
.icon-24-eject-mono:before{
    font-size:24px;
    content:"\eb8c"
}
.icon-24-electricity-bold:before{
    font-size:24px;
    content:"\eb8d"
}
.icon-24-electricity-mono:before{
    font-size:24px;
    content:"\eb8e"
}
.icon-24-email-add-bold:before{
    font-size:24px;
    content:"\eb8f"
}
.icon-24-email-add-mono:before{
    font-size:24px;
    content:"\eb90"
}
.icon-24-email-asterisk-bold:before{
    font-size:24px;
    content:"\eb91"
}
.icon-24-email-asterisk-mono:before{
    font-size:24px;
    content:"\eb92"
}
.icon-24-email-default-bold:before{
    font-size:24px;
    content:"\eb93"
}
.icon-24-email-default-mono:before{
    font-size:24px;
    content:"\eb94"
}
.icon-24-email-enter-bold:before{
    font-size:24px;
    content:"\eb95"
}
.icon-24-email-enter-mono:before{
    font-size:24px;
    content:"\eb96"
}
.icon-24-email-exit-bold:before{
    font-size:24px;
    content:"\eb97"
}
.icon-24-email-exit-mono:before{
    font-size:24px;
    content:"\eb98"
}
.icon-24-email-flag-bold:before{
    font-size:24px;
    content:"\eb99"
}
.icon-24-email-flag-mono:before{
    font-size:24px;
    content:"\eb9a"
}
.icon-24-email-lock-bold:before{
    font-size:24px;
    content:"\eb9b"
}
.icon-24-email-lock-mono:before{
    font-size:24px;
    content:"\eb9c"
}
.icon-24-email-open-bold:before{
    font-size:24px;
    content:"\eb9d"
}
.icon-24-email-open-mono:before{
    font-size:24px;
    content:"\eb9e"
}
.icon-24-email-prohibited-bold:before{
    font-size:24px;
    content:"\eb9f"
}
.icon-24-email-prohibited-mono:before{
    font-size:24px;
    content:"\eba0"
}
.icon-24-email-remove-bold:before{
    font-size:24px;
    content:"\eba1"
}
.icon-24-email-remove-mono:before{
    font-size:24px;
    content:"\eba2"
}
.icon-24-enlarge-bold:before{
    font-size:24px;
    content:"\eba3"
}
.icon-24-enlarge-mono:before{
    font-size:24px;
    content:"\eba4"
}
.icon-24-externalLink-bold:before{
    font-size:24px;
    content:"\eba5"
}
.icon-24-externalLink-mono:before{
    font-size:24px;
    content:"\eba6"
}
.icon-24-fastBackward-bold:before{
    font-size:24px;
    content:"\eba7"
}
.icon-24-fastBackward-mono:before{
    font-size:24px;
    content:"\eba8"
}
.icon-24-fastFoward-bold:before{
    font-size:24px;
    content:"\eba9"
}
.icon-24-fastFoward-mono:before{
    font-size:24px;
    content:"\ebaa"
}
.icon-24-favorites-bold:before{
    font-size:24px;
    content:"\ebab"
}
.icon-24-favorites-mono:before{
    font-size:24px;
    content:"\ebac"
}
.icon-24-film-bold:before{
    font-size:24px;
    content:"\ebad"
}
.icon-24-film-mono:before{
    font-size:24px;
    content:"\ebae"
}
.icon-24-filter-bold:before{
    font-size:24px;
    content:"\ebaf"
}
.icon-24-filter-mono:before{
    font-size:24px;
    content:"\ebb0"
}
.icon-24-firstPage-bold:before{
    font-size:24px;
    content:"\ebb1"
}
.icon-24-firstPage-mono:before{
    font-size:24px;
    content:"\ebb2"
}
.icon-24-flag-bold:before{
    font-size:24px;
    content:"\ebb3"
}
.icon-24-flag-mono:before{
    font-size:24px;
    content:"\ebb4"
}
.icon-24-focus-bold:before{
    font-size:24px;
    content:"\ebb5"
}
.icon-24-focus-mono:before{
    font-size:24px;
    content:"\ebb6"
}
.icon-24-folder-add-bold:before{
    font-size:24px;
    content:"\ebb7"
}
.icon-24-folder-add-mono:before{
    font-size:24px;
    content:"\ebb8"
}
.icon-24-folder-closed-bold:before{
    font-size:24px;
    content:"\ebb9"
}
.icon-24-folder-closed-mono:before{
    font-size:24px;
    content:"\ebba"
}
.icon-24-folder-flag-bold:before{
    font-size:24px;
    content:"\ebbb"
}
.icon-24-folder-flag-mono:before{
    font-size:24px;
    content:"\ebbc"
}
.icon-24-folder-lock-bold:before{
    font-size:24px;
    content:"\ebbd"
}
.icon-24-folder-lock-mono:before{
    font-size:24px;
    content:"\ebbe"
}
.icon-24-folder-open-bold:before{
    font-size:24px;
    content:"\ebbf"
}
.icon-24-folder-open-mono:before{
    font-size:24px;
    content:"\ebc0"
}
.icon-24-folder-remove-bold:before{
    font-size:24px;
    content:"\ebc1"
}
.icon-24-folder-remove-mono:before{
    font-size:24px;
    content:"\ebc2"
}
.icon-24-foward-bold:before{
    font-size:24px;
    content:"\ebc3"
}
.icon-24-foward-mono:before{
    font-size:24px;
    content:"\ebc4"
}
.icon-24-funnel-bold:before{
    font-size:24px;
    content:"\ebc5"
}
.icon-24-funnel-mono:before{
    font-size:24px;
    content:"\ebc6"
}
.icon-24-gender-female-bold:before{
    font-size:24px;
    content:"\ebc7"
}
.icon-24-gender-female-mono:before{
    font-size:24px;
    content:"\ebc8"
}
.icon-24-gender-male-bold:before{
    font-size:24px;
    content:"\ebc9"
}
.icon-24-gender-male-mono:before{
    font-size:24px;
    content:"\ebca"
}
.icon-24-gift-bold:before{
    font-size:24px;
    content:"\ebcb"
}
.icon-24-gift-mono:before{
    font-size:24px;
    content:"\ebcc"
}
.icon-24-global-mono:before{
    font-size:24px;
    content:"\ebcd"
}
.icon-24-green-bold:before{
    font-size:24px;
    content:"\ebce"
}
.icon-24-green-mono:before{
    font-size:24px;
    content:"\ebcf"
}
.icon-24-gridView-bold:before{
    font-size:24px;
    content:"\ebd0"
}
.icon-24-gridView-mono:before{
    font-size:24px;
    content:"\ebd1"
}
.icon-24-hamburgerMenu-bold:before{
    font-size:24px;
    content:"\ebd2"
}
.icon-24-hamburgerMenu-mono:before{
    font-size:24px;
    content:"\ebd3"
}
.icon-24-hand-down-mono:before{
    font-size:24px;
    content:"\ebd4"
}
.icon-24-hand-left-mono:before{
    font-size:24px;
    content:"\ebd5"
}
.icon-24-hand-right-mono:before{
    font-size:24px;
    content:"\ebd6"
}
.icon-24-hand-up-mono:before{
    font-size:24px;
    content:"\ebd7"
}
.icon-24-home-bold:before{
    font-size:24px;
    content:"\ebd8"
}
.icon-24-home-mono:before{
    font-size:24px;
    content:"\ebd9"
}
.icon-24-hot-bold:before{
    font-size:24px;
    content:"\ebda"
}
.icon-24-hot-mono:before{
    font-size:24px;
    content:"\ebdb"
}
.icon-24-image-bold:before{
    font-size:24px;
    content:"\ebdc"
}
.icon-24-image-mono:before{
    font-size:24px;
    content:"\ebdd"
}
.icon-24-info-bold:before{
    font-size:24px;
    content:"\ebde"
}
.icon-24-info-mono:before{
    font-size:24px;
    content:"\ebdf"
}
.icon-24-iPhone-bold:before{
    font-size:24px;
    content:"\ebe0"
}
.icon-24-iPhone-mono:before{
    font-size:24px;
    content:"\ebe1"
}
.icon-24-justify-bold:before{
    font-size:24px;
    content:"\ebe2"
}
.icon-24-justify-mono:before{
    font-size:24px;
    content:"\ebe3"
}
.icon-24-key-bold:before{
    font-size:24px;
    content:"\ebe4"
}
.icon-24-key-mono:before{
    font-size:24px;
    content:"\ebe5"
}
.icon-24-lastPage-bold:before{
    font-size:24px;
    content:"\ebe6"
}
.icon-24-lastPage-mono:before{
    font-size:24px;
    content:"\ebe7"
}
.icon-24-lightbulb-bold:before{
    font-size:24px;
    content:"\ebe8"
}
.icon-24-lightbulb-mono:before{
    font-size:24px;
    content:"\ebe9"
}
.icon-24-link-bold:before{
    font-size:24px;
    content:"\ebea"
}
.icon-24-link-mono:before{
    font-size:24px;
    content:"\ebeb"
}
.icon-24-location-bold:before{
    font-size:24px;
    content:"\ebec"
}
.icon-24-location-mono:before{
    font-size:24px;
    content:"\ebed"
}
.icon-24-lock-closed-bold:before{
    font-size:24px;
    content:"\ebee"
}
.icon-24-lock-closed-mono:before{
    font-size:24px;
    content:"\ebef"
}
.icon-24-lock-open-bold:before{
    font-size:24px;
    content:"\ebf0"
}
.icon-24-lock-open-mono:before{
    font-size:24px;
    content:"\ebf1"
}
.icon-24-logBook-bold:before{
    font-size:24px;
    content:"\ebf2"
}
.icon-24-logBook-mono:before{
    font-size:24px;
    content:"\ebf3"
}
.icon-24-magnetize-bold:before{
    font-size:24px;
    content:"\ebf4"
}
.icon-24-magnetize-mono:before{
    font-size:24px;
    content:"\ebf5"
}
.icon-24-measure-bold:before{
    font-size:24px;
    content:"\ebf6"
}
.icon-24-measure-mono:before{
    font-size:24px;
    content:"\ebf7"
}
.icon-24-minus-bold:before{
    font-size:24px;
    content:"\ebf8"
}
.icon-24-minus-mono:before{
    font-size:24px;
    content:"\ebf9"
}
.icon-24-money-canadianDollar-bold:before{
    font-size:24px;
    content:"\ebfa"
}
.icon-24-money-canadianDollar-mono:before{
    font-size:24px;
    content:"\ebfb"
}
.icon-24-money-dollar-bold:before{
    font-size:24px;
    content:"\ebfc"
}
.icon-24-money-dollar-mono:before{
    font-size:24px;
    content:"\ebfd"
}
.icon-24-money-euro-bold:before{
    font-size:24px;
    content:"\ebfe"
}
.icon-24-money-euro-mono:before{
    font-size:24px;
    content:"\ebff"
}
.icon-24-money-pound-bold:before{
    font-size:24px;
    content:"\ec00"
}
.icon-24-money-pound-mono:before{
    font-size:24px;
    content:"\ec01"
}
.icon-24-monitor-bold:before{
    font-size:24px;
    content:"\ec02"
}
.icon-24-monitor-mono:before{
    font-size:24px;
    content:"\ec03"
}
.icon-24-moreHori-bold:before{
    font-size:24px;
    content:"\ec04"
}
.icon-24-moreHori-mono:before{
    font-size:24px;
    content:"\ec05"
}
.icon-24-moreVert-bold:before{
    font-size:24px;
    content:"\ec06"
}
.icon-24-moreVert-mono:before{
    font-size:24px;
    content:"\ec07"
}
.icon-24-moreWindows-bold:before{
    font-size:24px;
    content:"\ec08"
}
.icon-24-moreWindows-mono:before{
    font-size:24px;
    content:"\ec09"
}
.icon-24-move-bold:before{
    font-size:24px;
    content:"\ec0a"
}
.icon-24-move-mono:before{
    font-size:24px;
    content:"\ec0b"
}
.icon-24-multiInstrument-bold:before{
    font-size:24px;
    content:"\ec0c"
}
.icon-24-multiInstrument-mono:before{
    font-size:24px;
    content:"\ec0d"
}
.icon-24-newWindow-bold_1:before{
    font-size:24px;
    content:"\ec0e"
}
.icon-24-newWindow-bold:before{
    font-size:24px;
    content:"\ec0f"
}
.icon-24-newWindow-mono_1:before{
    font-size:24px;
    content:"\ec10"
}
.icon-24-newWindow-mono:before{
    font-size:24px;
    content:"\ec11"
}
.icon-24-note-bold:before{
    font-size:24px;
    content:"\ec12"
}
.icon-24-note-mono:before{
    font-size:24px;
    content:"\ec13"
}
.icon-24-pause-bold:before{
    font-size:24px;
    content:"\ec14"
}
.icon-24-pause-mono:before{
    font-size:24px;
    content:"\ec15"
}
.icon-24-phone-bold:before{
    font-size:24px;
    content:"\ec16"
}
.icon-24-phone-mono:before{
    font-size:24px;
    content:"\ec17"
}
.icon-24-phoneVolume-bold:before{
    font-size:24px;
    content:"\ec18"
}
.icon-24-phoneVolume-mono:before{
    font-size:24px;
    content:"\ec19"
}
.icon-24-pieChart-bold:before{
    font-size:24px;
    content:"\ec1a"
}
.icon-24-pieChart-mono:before{
    font-size:24px;
    content:"\ec1b"
}
.icon-24-pin-bold:before{
    font-size:24px;
    content:"\ec1c"
}
.icon-24-pin-mono:before{
    font-size:24px;
    content:"\ec1d"
}
.icon-24-play-bold:before{
    font-size:24px;
    content:"\ec1e"
}
.icon-24-play-mono:before{
    font-size:24px;
    content:"\ec1f"
}
.icon-24-playButton-bold:before{
    font-size:24px;
    content:"\ec20"
}
.icon-24-playButton-mono:before{
    font-size:24px;
    content:"\ec21"
}
.icon-24-plus-bold:before{
    font-size:24px;
    content:"\ec22"
}
.icon-24-plus-mono:before{
    font-size:24px;
    content:"\ec23"
}
.icon-24-power-bold:before{
    font-size:24px;
    content:"\ec24"
}
.icon-24-power-mono:before{
    font-size:24px;
    content:"\ec25"
}
.icon-24-print-bold:before{
    font-size:24px;
    content:"\ec26"
}
.icon-24-print-mono:before{
    font-size:24px;
    content:"\ec27"
}
.icon-24-profile-bold:before{
    font-size:24px;
    content:"\ec28"
}
.icon-24-profile-mono:before{
    font-size:24px;
    content:"\ec29"
}
.icon-24-profileAdd-bold:before{
    font-size:24px;
    content:"\ec2a"
}
.icon-24-profileAdd-mono:before{
    font-size:24px;
    content:"\ec2b"
}
.icon-24-profileDelete-bold:before{
    font-size:24px;
    content:"\ec2c"
}
.icon-24-profileDelete-mono:before{
    font-size:24px;
    content:"\ec2d"
}
.icon-24-prohibited-bold:before{
    font-size:24px;
    content:"\ec2e"
}
.icon-24-prohibited-mono:before{
    font-size:24px;
    content:"\ec2f"
}
.icon-24-promotions-bold:before{
    font-size:24px;
    content:"\ec30"
}
.icon-24-promotions-mono:before{
    font-size:24px;
    content:"\ec31"
}
.icon-24-pushPin-bold:before{
    font-size:24px;
    content:"\ec32"
}
.icon-24-pushPin-mono:before{
    font-size:24px;
    content:"\ec33"
}
.icon-24-qr-bold:before{
    font-size:24px;
    content:"\ec34"
}
.icon-24-qr-mono:before{
    font-size:24px;
    content:"\ec35"
}
.icon-24-question-bold:before{
    font-size:24px;
    content:"\ec36"
}
.icon-24-question-mono:before{
    font-size:24px;
    content:"\ec37"
}
.icon-24-random-bold:before{
    font-size:24px;
    content:"\ec38"
}
.icon-24-random-mono:before{
    font-size:24px;
    content:"\ec39"
}
.icon-24-rating-bold:before{
    font-size:24px;
    content:"\ec3a"
}
.icon-24-rating-mono:before{
    font-size:24px;
    content:"\ec3b"
}
.icon-24-recycle-bold:before{
    font-size:24px;
    content:"\ec3c"
}
.icon-24-recycle-mono:before{
    font-size:24px;
    content:"\ec3d"
}
.icon-24-refresh-bold:before{
    font-size:24px;
    content:"\ec3e"
}
.icon-24-refresh-mono:before{
    font-size:24px;
    content:"\ec3f"
}
.icon-24-repeat-bold:before{
    font-size:24px;
    content:"\ec40"
}
.icon-24-repeat-mono:before{
    font-size:24px;
    content:"\ec41"
}
.icon-24-resizeLarge-bold:before{
    font-size:24px;
    content:"\ec42"
}
.icon-24-resizeLarge-mono:before{
    font-size:24px;
    content:"\ec43"
}
.icon-24-resizeSmall-bold:before{
    font-size:24px;
    content:"\ec44"
}
.icon-24-resizeSmall-mono:before{
    font-size:24px;
    content:"\ec45"
}
.icon-24-resource-bold:before{
    font-size:24px;
    content:"\ec46"
}
.icon-24-resource-mono:before{
    font-size:24px;
    content:"\ec47"
}
.icon-24-restart-bold:before{
    font-size:24px;
    content:"\ec48"
}
.icon-24-restart-mono:before{
    font-size:24px;
    content:"\ec49"
}
.icon-24-return-bold:before{
    font-size:24px;
    content:"\ec4a"
}
.icon-24-return-mono:before{
    font-size:24px;
    content:"\ec4b"
}
.icon-24-reverseLeft-bold:before{
    font-size:24px;
    content:"\ec4c"
}
.icon-24-reverseLeft-mono:before{
    font-size:24px;
    content:"\ec4d"
}
.icon-24-reverseRight-bold:before{
    font-size:24px;
    content:"\ec4e"
}
.icon-24-reverseRight-mono:before{
    font-size:24px;
    content:"\ec4f"
}
.icon-24-rewind-bold:before{
    font-size:24px;
    content:"\ec50"
}
.icon-24-rewind-mono:before{
    font-size:24px;
    content:"\ec51"
}
.icon-24-search-bold:before{
    font-size:24px;
    content:"\ec52"
}
.icon-24-search-mono:before{
    font-size:24px;
    content:"\ec53"
}
.icon-24-settings-alt-bold:before{
    font-size:24px;
    content:"\ec54"
}
.icon-24-settings-alt-mono:before{
    font-size:24px;
    content:"\ec55"
}
.icon-24-settings-bold:before{
    font-size:24px;
    content:"\ec56"
}
.icon-24-settings-mono:before{
    font-size:24px;
    content:"\ec57"
}
.icon-24-share-alt-bold:before{
    font-size:24px;
    content:"\ec58"
}
.icon-24-share-alt-mono:before{
    font-size:24px;
    content:"\ec59"
}
.icon-24-share-bold:before{
    font-size:24px;
    content:"\ec5a"
}
.icon-24-share-mono:before{
    font-size:24px;
    content:"\ec5b"
}
.icon-24-shipping-bold:before{
    font-size:24px;
    content:"\ec5c"
}
.icon-24-shipping-mono:before{
    font-size:24px;
    content:"\ec5d"
}
.icon-24-signal-bold:before{
    font-size:24px;
    content:"\ec5e"
}
.icon-24-signal-mono:before{
    font-size:24px;
    content:"\ec5f"
}
.icon-24-sleep-bold:before{
    font-size:24px;
    content:"\ec60"
}
.icon-24-sleep-mono:before{
    font-size:24px;
    content:"\ec61"
}
.icon-24-sortArrows-bold:before{
    font-size:24px;
    content:"\ec62"
}
.icon-24-sortArrows-mono:before{
    font-size:24px;
    content:"\ec63"
}
.icon-24-speaker-1-bold:before{
    font-size:24px;
    content:"\ec64"
}
.icon-24-speaker-1-mono:before{
    font-size:24px;
    content:"\ec65"
}
.icon-24-speaker-2-bold:before{
    font-size:24px;
    content:"\ec66"
}
.icon-24-speaker-2-mono:before{
    font-size:24px;
    content:"\ec67"
}
.icon-24-speaker-full-bold:before{
    font-size:24px;
    content:"\ec68"
}
.icon-24-speaker-full-mono:before{
    font-size:24px;
    content:"\ec69"
}
.icon-24-speaker-off-bold:before{
    font-size:24px;
    content:"\ec6a"
}
.icon-24-speaker-off-mono:before{
    font-size:24px;
    content:"\ec6b"
}
.icon-24-stop-bold:before{
    font-size:24px;
    content:"\ec6c"
}
.icon-24-stop-mono:before{
    font-size:24px;
    content:"\ec6d"
}
.icon-24-stopwatch-bold:before{
    font-size:24px;
    content:"\ec6e"
}
.icon-24-stopwatch-mono:before{
    font-size:24px;
    content:"\ec6f"
}
.icon-24-success-bold:before{
    font-size:24px;
    content:"\ec70"
}
.icon-24-success-mono:before{
    font-size:24px;
    content:"\ec71"
}
.icon-24-support-bold:before{
    font-size:24px;
    content:"\ec72"
}
.icon-24-support-mono:before{
    font-size:24px;
    content:"\ec73"
}
.icon-24-table-bold:before{
    font-size:24px;
    content:"\ec74"
}
.icon-24-table-mono:before{
    font-size:24px;
    content:"\ec75"
}
.icon-24-tag-bold:before{
    font-size:24px;
    content:"\ec76"
}
.icon-24-tag-mono:before{
    font-size:24px;
    content:"\ec77"
}
.icon-24-techDocumentation-bold:before{
    font-size:24px;
    content:"\ec78"
}
.icon-24-techDocumentation-mono:before{
    font-size:24px;
    content:"\ec79"
}
.icon-24-thumbsDowon-bold:before{
    font-size:24px;
    content:"\ec7a"
}
.icon-24-thumbsDowon-mono:before{
    font-size:24px;
    content:"\ec7b"
}
.icon-24-thumbsUp-bold:before{
    font-size:24px;
    content:"\ec7c"
}
.icon-24-thumbsUp-mono:before{
    font-size:24px;
    content:"\ec7d"
}
.icon-24-tint-bold:before{
    font-size:24px;
    content:"\ec7e"
}
.icon-24-tint-mono:before{
    font-size:24px;
    content:"\ec7f"
}
.icon-24-unreturn-bold:before{
    font-size:24px;
    content:"\ec80"
}
.icon-24-unreturn-mono:before{
    font-size:24px;
    content:"\ec81"
}
.icon-24-unshare-bold:before{
    font-size:24px;
    content:"\ec82"
}
.icon-24-unshare-mono:before{
    font-size:24px;
    content:"\ec83"
}
.icon-24-up-arrow-bold-copy1 .path1:before{
    font-size:24px;
    content:"\ec84";
    color:#598db5
}
.icon-24-up-arrow-bold-copy1 .path2:before{
    font-size:24px;
    content:"\ec85";
    margin-left:-1em;
    color:#fff
}
.icon-24-up-arrow-bold-copy1 .path3:before{
    font-size:24px;
    content:"\ec86";
    margin-left:-1em;
    color:#fff46d
}
.icon-24-up-arrow-bold-copy1 .path4:before{
    font-size:24px;
    content:"\ec87";
    margin-left:-1em;
    color:#ed1c24
}
.icon-24-up-arrow-bold-copy1 .path5:before{
    font-size:24px;
    content:"\ec88";
    margin-left:-1em;
    color:#222
}
.icon-24-upload-bold:before{
    font-size:24px;
    content:"\ec89"
}
.icon-24-upload-mono:before{
    font-size:24px;
    content:"\ec8a"
}
.icon-24-vcard-bold:before{
    font-size:24px;
    content:"\ec8b"
}
.icon-24-vcard-mono:before{
    font-size:24px;
    content:"\ec8c"
}
.icon-24-video-bold:before{
    font-size:24px;
    content:"\ec8d"
}
.icon-24-video-mono:before{
    font-size:24px;
    content:"\ec8e"
}
.icon-24-view-off-bold:before{
    font-size:24px;
    content:"\ec8f"
}
.icon-24-view-off-mono:before{
    font-size:24px;
    content:"\ec90"
}
.icon-24-view-on-bold:before{
    font-size:24px;
    content:"\ec91"
}
.icon-24-view-on-mono:before{
    font-size:24px;
    content:"\ec92"
}
.icon-24-warning-bold:before{
    font-size:24px;
    content:"\ec93"
}
.icon-24-warning-mono:before{
    font-size:24px;
    content:"\ec94"
}
.icon-24-webinar-bold:before{
    font-size:24px;
    content:"\ec95"
}
.icon-24-webinar-mono:before{
    font-size:24px;
    content:"\ec96"
}
.icon-24-wireless-alt-bold:before{
    font-size:24px;
    content:"\ec97"
}
.icon-24-wireless-alt-mono:before{
    font-size:24px;
    content:"\ec98"
}
.icon-24-wireless-bold:before{
    font-size:24px;
    content:"\ec99"
}
.icon-24-wireless-mono:before{
    font-size:24px;
    content:"\ec9a"
}
.icon-24-zoomIn-bold:before{
    font-size:24px;
    content:"\ec9b"
}
.icon-24-zoomIn-mono:before{
    font-size:24px;
    content:"\ec9c"
}
.icon-24-zoomOut-bold:before{
    font-size:24px;
    content:"\ec9d"
}
.icon-24-zoomOut-mono_1:before{
    font-size:24px;
    content:"\ec9e"
}
.icon-24-zoomOut-mono:before{
    font-size:24px;
    content:"\ec9f"
}
.icon-16-addressBook-bold:before{
    font-size:16px;
    content:"\eca0"
}
.icon-16-addressBook-mono:before{
    font-size:16px;
    content:"\eca1"
}
.icon-16-alert-bold:before{
    font-size:16px;
    content:"\eca2"
}
.icon-16-alert-mono:before{
    font-size:16px;
    content:"\eca3"
}
.icon-16-align-center-bold:before{
    font-size:16px;
    content:"\eca4"
}
.icon-16-align-center-mono:before{
    font-size:16px;
    content:"\eca5"
}
.icon-16-align-left-bold:before{
    font-size:16px;
    content:"\eca6"
}
.icon-16-align-left-mono:before{
    font-size:16px;
    content:"\eca7"
}
.icon-16-align-right-bold:before{
    font-size:16px;
    content:"\eca8"
}
.icon-16-align-right-mono:before{
    font-size:16px;
    content:"\eca9"
}
.icon-16-analyze-bold:before{
    font-size:16px;
    content:"\ecaa"
}
.icon-24-arrow-down-bold:before{
    font-size:16px;
    content:"\ecab"
}
.icon-16-arrow-down-mono:before{
    font-size:16px;
    content:"\ecac"
}
.icon-16-arrow-left-bold:before{
    font-size:16px;
    content:"\ecad"
}
.icon-24-arrow-left-mono:before{
    font-size:16px;
    content:"\ecae"
}
.icon-16-arrow-right-bold:before{
    font-size:16px;
    content:"\ecaf"
}
.icon-16-arrow-right-mono:before{
    font-size:16px;
    content:"\ecb0"
}
.icon-16-arrow-up-bold:before{
    font-size:16px;
    content:"\ecb1"
}
.icon-16-arrow-up-mono:before{
    font-size:16px;
    content:"\ecb2"
}
.icon-16-asterisk-bold:before{
    font-size:16px;
    content:"\ecb3"
}
.icon-16-asterisk-mono:before{
    font-size:16px;
    content:"\ecb4"
}
.icon-16-awake-bold:before{
    font-size:16px;
    content:"\ecb5"
}
.icon-16-awake-mono:before{
    font-size:16px;
    content:"\ecb6"
}
.icon-16-award-bold:before{
    font-size:16px;
    content:"\ecb7"
}
.icon-16-award-mono:before{
    font-size:16px;
    content:"\ecb8"
}
.icon-16-barcode-bold:before{
    font-size:16px;
    content:"\ecb9"
}
.icon-16-barcode-mono:before{
    font-size:16px;
    content:"\ecba"
}
.icon-16-bell-bold:before{
    font-size:16px;
    content:"\ecbb"
}
.icon-16-bell-mono:before{
    font-size:16px;
    content:"\ecbc"
}
.icon-16-bin-bold:before{
    font-size:16px;
    content:"\ecbd"
}
.icon-16-bin-mono:before{
    font-size:16px;
    content:"\ecbe"
}
.icon-16-bluetooth-mono:before{
    font-size:16px;
    content:"\ecbf"
}
.icon-16-bookmark-bold:before{
    font-size:16px;
    content:"\ecc0"
}
.icon-16-bookmark-mono:before{
    font-size:16px;
    content:"\ecc1"
}
.icon-16-bulletedList-bold:before{
    font-size:16px;
    content:"\ecc2"
}
.icon-16-bulletedList-mono:before{
    font-size:16px;
    content:"\ecc3"
}
.icon-16-caculator-bold:before{
    font-size:16px;
    content:"\ecc4"
}
.icon-16-calendar-bold:before{
    font-size:16px;
    content:"\ecc5"
}
.icon-16-calendar-mono:before{
    font-size:16px;
    content:"\ecc6"
}
.icon-16-camera-bold:before{
    font-size:16px;
    content:"\ecc7"
}
.icon-16-camera-mono:before{
    font-size:16px;
    content:"\ecc8"
}
.icon-16-cart-add-bold:before{
    font-size:16px;
    content:"\ecc9"
}
.icon-16-cart-add-mono:before{
    font-size:16px;
    content:"\ecca"
}
.icon-16-cart-default-bold:before{
    font-size:16px;
    content:"\eccb"
}
.icon-16-cart-default-mono:before{
    font-size:16px;
    content:"\eccc"
}
.icon-16-cart-remove-bold:before{
    font-size:16px;
    content:"\eccd"
}
.icon-16-cart-remove-mono:before{
    font-size:16px;
    content:"\ecce"
}
.icon-16-catalog-bold:before{
    font-size:16px;
    content:"\eccf"
}
.icon-16-catalog-mono:before{
    font-size:16px;
    content:"\ecd0"
}
.icon-16-chat-bold:before{
    font-size:16px;
    content:"\ecd1"
}
.icon-16-chat-mono:before{
    font-size:16px;
    content:"\ecd2"
}
.icon-16-checkedBox-bold:before{
    font-size:16px;
    content:"\ecd3"
}
.icon-16-checkedBox-mono:before{
    font-size:16px;
    content:"\ecd4"
}
.icon-16-checkmark-bold:before{
    font-size:16px;
    content:"\ecd5"
}
.icon-checkmark-mono:before{
    font-size:16px;
    content:"\ecd6"
}
.icon-16-chevron-down-bold:before{
    font-size:16px;
    content:"\ecd7"
}
.icon-16-chevron-down-mono:before{
    font-size:16px;
    content:"\ecd8"
}
.icon-16-chevron-left-bold:before{
    font-size:16px;
    content:"\ecd9"
}
.icon-16-chevron-left-mono:before{
    font-size:16px;
    content:"\ecda"
}
.icon-16-chevron-right-bold:before{
    font-size:16px;
    content:"\ecdb"
}
.icon-16-chevron-right-mono:before{
    font-size:16px;
    content:"\ecdc"
}
.icon-16-chevron-up-bold:before{
    font-size:16px;
    content:"\ecdd"
}
.icon-16-chevron-up-mono:before{
    font-size:16px;
    content:"\ecde"
}
.icon-16-clipboard-bold:before{
    font-size:16px;
    content:"\ecdf"
}
.icon-16-clipboard-mono:before{
    font-size:16px;
    content:"\ece0"
}
.icon-16-clock-bold:before{
    font-size:16px;
    content:"\ece1"
}
.icon-16-clock-mono:before{
    font-size:16px;
    content:"\ece2"
}
.icon-16-close-bold:before{
    font-size:16px;
    content:"\ece3"
}
.icon-16-close-mono:before{
    font-size:16px;
    content:"\ece4"
}
.icon-16-cloud-bold:before{
    font-size:16px;
    content:"\ece5"
}
.icon-16-cloud-mono:before{
    font-size:16px;
    content:"\ece6"
}
.icon-16-cloudDownload-bold:before{
    font-size:16px;
    content:"\ece7"
}
.icon-16-cloudDownload-mono:before{
    font-size:16px;
    content:"\ece8"
}
.icon-16-cloudUpload-bold:before{
    font-size:16px;
    content:"\ece9"
}
.icon-16-cloudUpload-mono:before{
    font-size:16px;
    content:"\ecea"
}
.icon-16-cold-bold:before{
    font-size:16px;
    content:"\eceb"
}
.icon-16-comment-bold:before{
    font-size:16px;
    content:"\ecec"
}
.icon-16-comment-mono:before{
    font-size:16px;
    content:"\eced"
}
.icon-16-community-bold:before{
    font-size:16px;
    content:"\ecee"
}
.icon-16-community-mono:before{
    font-size:16px;
    content:"\ecef"
}
.icon-16-compass-bold:before{
    font-size:16px;
    content:"\ecf0"
}
.icon-16-compass-mono:before{
    font-size:16px;
    content:"\ecf1"
}
.icon-16-contrast-bold:before{
    font-size:16px;
    content:"\ecf2"
}
.icon-16-contrast-mono:before{
    font-size:16px;
    content:"\ecf3"
}
.icon-16-creditCard-bold:before{
    font-size:16px;
    content:"\ecf4"
}
.icon-16-creditCard-mono:before{
    font-size:16px;
    content:"\ecf5"
}
.icon-16-crop-bold:before{
    font-size:16px;
    content:"\ecf6"
}
.icon-16-crop-mono:before{
    font-size:16px;
    content:"\ecf7"
}
.icon-16-customerApproved-bold:before{
    font-size:16px;
    content:"\ecf8"
}
.icon-16-customerApproved-mono:before{
    font-size:16px;
    content:"\ecf9"
}
.icon-16-cut-mono:before{
    font-size:16px;
    content:"\ecfa"
}
.icon-16-dashboard-bold:before{
    font-size:16px;
    content:"\ecfb"
}
.icon-16-dashboard-mono:before{
    font-size:16px;
    content:"\ecfc"
}
.icon-16-data-bold:before{
    font-size:16px;
    content:"\ecfd"
}
.icon-16-data-mono:before{
    font-size:16px;
    content:"\ecfe"
}
.icon-16-database-ban-bold:before{
    font-size:16px;
    content:"\ecff"
}
.icon-16-database-default-bold:before{
    font-size:16px;
    content:"\ed00"
}
.icon-16-database-lock-bold:before{
    font-size:16px;
    content:"\ed01"
}
.icon-16-database-minus-bold:before{
    font-size:16px;
    content:"\ed02"
}
.icon-16-database-plus-bold:before{
    font-size:16px;
    content:"\ed03"
}
.icon-16-database-plus-mono:before{
    font-size:16px;
    content:"\ed04"
}
.icon-16-delete-bold:before{
    font-size:16px;
    content:"\ed05"
}
.icon-16-delete-mono:before{
    font-size:16px;
    content:"\ed06"
}
.icon-16-direction-bold:before{
    font-size:16px;
    content:"\ed07"
}
.icon-16-direction-mono:before{
    font-size:16px;
    content:"\ed08"
}
.icon-16-divide-bold:before{
    font-size:16px;
    content:"\ed09"
}
.icon-16-divide-mono:before{
    font-size:16px;
    content:"\ed0a"
}
.icon-16-docs-7z-bold:before{
    font-size:16px;
    content:"\ed0b"
}
.icon-16-docs-7z-mono:before{
    font-size:16px;
    content:"\ed0c"
}
.icon-16-docs-cat-bold:before{
    font-size:16px;
    content:"\ed0d"
}
.icon-16-docs-cat-mono:before{
    font-size:16px;
    content:"\ed0e"
}
.icon-16-docs-css-bold:before{
    font-size:16px;
    content:"\ed0f"
}
.icon-16-docs-css-mono:before{
    font-size:16px;
    content:"\ed10"
}
.icon-16-docs-csv-bold:before{
    font-size:16px;
    content:"\ed11"
}
.icon-16-docs-csv-mono:before{
    font-size:16px;
    content:"\ed12"
}
.icon-16-docs-doc-bold:before{
    font-size:16px;
    content:"\ed13"
}
.icon-16-docs-doc-mono:before{
    font-size:16px;
    content:"\ed14"
}
.icon-16-docs-eps-bold:before{
    font-size:16px;
    content:"\ed15"
}
.icon-16-docs-eps-mono:before{
    font-size:16px;
    content:"\ed16"
}
.icon-16-docs-exe-bold:before{
    font-size:16px;
    content:"\ed17"
}
.icon-16-docs-exe-mono:before{
    font-size:16px;
    content:"\ed18"
}
.icon-16-docs-gif-bold:before{
    font-size:16px;
    content:"\ed19"
}
.icon-16-docs-gif-mono:before{
    font-size:16px;
    content:"\ed1a"
}
.icon-16-docs-gz-bold:before{
    font-size:16px;
    content:"\ed1b"
}
.icon-16-docs-gz-mono:before{
    font-size:16px;
    content:"\ed1c"
}
.icon-16-docs-ics-bold:before{
    font-size:16px;
    content:"\ed1d"
}
.icon-16-docs-ics-mono:before{
    font-size:16px;
    content:"\ed1e"
}
.icon-16-docs-img-bold:before{
    font-size:16px;
    content:"\ed1f"
}
.icon-16-docs-img-mono:before{
    font-size:16px;
    content:"\ed20"
}
.icon-16-docs-jpg-bold:before{
    font-size:16px;
    content:"\ed21"
}
.icon-16-docs-jpg-mono:before{
    font-size:16px;
    content:"\ed22"
}
.icon-16-docs-m4v-bold:before{
    font-size:16px;
    content:"\ed23"
}
.icon-16-docs-m4v-mono:before{
    font-size:16px;
    content:"\ed24"
}
.icon-16-docs-mp3-bold:before{
    font-size:16px;
    content:"\ed25"
}
.icon-16-docs-mp3-mono:before{
    font-size:16px;
    content:"\ed26"
}
.icon-16-docs-mp4-bold:before{
    font-size:16px;
    content:"\ed27"
}
.icon-16-docs-mp4-mono:before{
    font-size:16px;
    content:"\ed28"
}
.icon-16-docs-pdf-bold:before{
    font-size:16px;
    content:"\ed29"
}
.icon-16-docs-pdf-mono:before{
    font-size:16px;
    content:"\ed2a"
}
.icon-16-docs-png-bold:before{
    font-size:16px;
    content:"\ed2b"
}
.icon-16-docs-png-mono:before{
    font-size:16px;
    content:"\ed2c"
}
.icon-16-docs-pps-bold:before{
    font-size:16px;
    content:"\ed2d"
}
.icon-16-docs-pps-mono:before{
    font-size:16px;
    content:"\ed2e"
}
.icon-16-docs-ppt-bold:before{
    font-size:16px;
    content:"\ed2f"
}
.icon-16-docs-ppt-mono:before{
    font-size:16px;
    content:"\ed30"
}
.icon-16-docs-psd-bold:before{
    font-size:16px;
    content:"\ed31"
}
.icon-16-docs-psd-mono:before{
    font-size:16px;
    content:"\ed32"
}
.icon-16-docs-rar-bold:before{
    font-size:16px;
    content:"\ed33"
}
.icon-16-docs-rar-mono:before{
    font-size:16px;
    content:"\ed34"
}
.icon-16-docs-swf-bold:before{
    font-size:16px;
    content:"\ed35"
}
.icon-16-docs-swf-mono:before{
    font-size:16px;
    content:"\ed36"
}
.icon-16-docs-tar-bold:before{
    font-size:16px;
    content:"\ed37"
}
.icon-16-docs-tar-mono:before{
    font-size:16px;
    content:"\ed38"
}
.icon-16-docs-tgz-bold:before{
    font-size:16px;
    content:"\ed39"
}
.icon-16-docs-tgz-mono:before{
    font-size:16px;
    content:"\ed3a"
}
.icon-16-docs-tif-bold:before{
    font-size:16px;
    content:"\ed3b"
}
.icon-16-docs-tif-mono:before{
    font-size:16px;
    content:"\ed3c"
}
.icon-16-docs-txt-bold:before{
    font-size:16px;
    content:"\ed3d"
}
.icon-16-docs-txt-mono:before{
    font-size:16px;
    content:"\ed3e"
}
.icon-16-docs-vcf-bold:before{
    font-size:16px;
    content:"\ed3f"
}
.icon-16-docs-vcf-mono:before{
    font-size:16px;
    content:"\ed40"
}
.icon-16-docs-wmf-bold:before{
    font-size:16px;
    content:"\ed41"
}
.icon-16-docs-wmf-mono:before{
    font-size:16px;
    content:"\ed42"
}
.icon-16-docs-wmv-bold:before{
    font-size:16px;
    content:"\ed43"
}
.icon-16-docs-wmv-mono:before{
    font-size:16px;
    content:"\ed44"
}
.icon-16-docs-xls-bold:before{
    font-size:16px;
    content:"\ed45"
}
.icon-16-docs-xls-mono:before{
    font-size:16px;
    content:"\ed46"
}
.icon-16-docs-xml-bold:before{
    font-size:16px;
    content:"\ed47"
}
.icon-16-docs-xml-mono:before{
    font-size:16px;
    content:"\ed48"
}
.icon-16-docs-zip-bold:before{
    font-size:16px;
    content:"\ed49"
}
.icon-16-docs-zip-mono:before{
    font-size:16px;
    content:"\ed4a"
}
.icon-16-document-bold:before{
    font-size:16px;
    content:"\ed4b"
}
.icon-16-document-mono:before{
    font-size:16px;
    content:"\ed4c"
}
.icon-16-download-bold:before{
    font-size:16px;
    content:"\ed4d"
}
.icon-16-download-mono:before{
    font-size:16px;
    content:"\ed4e"
}
.icon-16-edit-default-bold:before{
    font-size:16px;
    content:"\ed4f"
}
.icon-16-edit-default-mono:before{
    font-size:16px;
    content:"\ed50"
}
.icon-16-eject-bold:before{
    font-size:16px;
    content:"\ed51"
}
.icon-16-eject-mono:before{
    font-size:16px;
    content:"\ed52"
}
.icon-16-electricity-bold:before{
    font-size:16px;
    content:"\ed53"
}
.icon-16-electricity-mono:before{
    font-size:16px;
    content:"\ed54"
}
.icon-16-email-default-bold:before{
    font-size:16px;
    content:"\ed55"
}
.icon-16-email-default-mono:before{
    font-size:16px;
    content:"\ed56"
}
.icon-16-email-open-bold:before{
    font-size:16px;
    content:"\ed57"
}
.icon-16-email-open-mono:before{
    font-size:16px;
    content:"\ed58"
}
.icon-16-enlarge-bold:before{
    font-size:16px;
    content:"\ed59"
}
.icon-16-enlarge-mono:before{
    font-size:16px;
    content:"\ed5a"
}
.icon-16-externalLink-mono:before{
    font-size:16px;
    content:"\ed5b"
}
.icon-16-fastBackward-bold:before{
    font-size:16px;
    content:"\ed5c"
}
.icon-16-fastBackward-mono:before{
    font-size:16px;
    content:"\ed5d"
}
.icon-16-fastFoward-bold:before{
    font-size:16px;
    content:"\ed5e"
}
.icon-16-fastFoward-mono:before{
    font-size:16px;
    content:"\ed5f"
}
.icon-16-favorites-bold:before{
    font-size:16px;
    content:"\ed60"
}
.icon-16-favorites-mono:before{
    font-size:16px;
    content:"\ed61"
}
.icon-16-film-bold:before{
    font-size:16px;
    content:"\ed62"
}
.icon-16-film-mono:before{
    font-size:16px;
    content:"\ed63"
}
.icon-16-filter-bold:before{
    font-size:16px;
    content:"\ed64"
}
.icon-16-filter-mono:before{
    font-size:16px;
    content:"\ed65"
}
.icon-16-firstPage-bold:before{
    font-size:16px;
    content:"\ed66"
}
.icon-16-firstPage-mono:before{
    font-size:16px;
    content:"\ed67"
}
.icon-16-flag-bold:before{
    font-size:16px;
    content:"\ed68"
}
.icon-16-flag-mono:before{
    font-size:16px;
    content:"\ed69"
}
.icon-16-focus-mono:before{
    font-size:16px;
    content:"\ed6a"
}
.icon-16-folder-closed-bold:before{
    font-size:16px;
    content:"\ed6b"
}
.icon-16-folder-closed-mono:before{
    font-size:16px;
    content:"\ed6c"
}
.icon-16-folder-open-mono:before{
    font-size:16px;
    content:"\ed6d"
}
.icon-16-forward-bold:before{
    font-size:16px;
    content:"\ed6e"
}
.icon-16-forward-mono:before{
    font-size:16px;
    content:"\ed6f"
}
.icon-16-funnel-bold:before{
    font-size:16px;
    content:"\ed70"
}
.icon-16-funnel-mono:before{
    font-size:16px;
    content:"\ed71"
}
.icon-16-gender-female-bold:before{
    font-size:16px;
    content:"\ed72"
}
.icon-16-gender-female-mono:before{
    font-size:16px;
    content:"\ed73"
}
.icon-16-gender-male-bold:before{
    font-size:16px;
    content:"\ed74"
}
.icon-16-gender-male-mono:before{
    font-size:16px;
    content:"\ed75"
}
.icon-16-gift-mono:before{
    font-size:16px;
    content:"\ed76"
}
.icon-16-green-mono:before{
    font-size:16px;
    content:"\ed77"
}
.icon-16-gridView-bold:before{
    font-size:16px;
    content:"\ed78"
}
.icon-16-gridView-mono:before{
    font-size:16px;
    content:"\ed79"
}
.icon-16-hamburgerMenu-bold:before{
    font-size:16px;
    content:"\ed7a"
}
.icon-16-hamburgerMenu-mono:before{
    font-size:16px;
    content:"\ed7b"
}
.icon-16-hand-down-mono:before{
    font-size:16px;
    content:"\ed7c"
}
.icon-16-hand-left-mono:before{
    font-size:16px;
    content:"\ed7d"
}
.icon-16-hand-right-mono:before{
    font-size:16px;
    content:"\ed7e"
}
.icon-16-hand-up-mono:before{
    font-size:16px;
    content:"\ed7f"
}
.icon-16-home-bold:before{
    font-size:16px;
    content:"\ed80"
}
.icon-16-home-mono:before{
    font-size:16px;
    content:"\ed81"
}
.icon-16-hot-bold:before{
    font-size:16px;
    content:"\ed82"
}
.icon-16-hot-mono:before{
    font-size:16px;
    content:"\ed83"
}
.icon-16-image-bold:before{
    font-size:16px;
    content:"\ed84"
}
.icon-16-image-mono:before{
    font-size:16px;
    content:"\ed85"
}
.icon-16-info-bold:before{
    font-size:16px;
    content:"\ed86"
}
.icon-16-info-mono:before{
    font-size:16px;
    content:"\ed87"
}
.icon-16-iPhone-bold:before{
    font-size:16px;
    content:"\ed88"
}
.icon-16-key-bold:before{
    font-size:16px;
    content:"\ed89"
}
.icon-16-key-mono:before{
    font-size:16px;
    content:"\ed8a"
}
.icon-16-lastPage-bold:before{
    font-size:16px;
    content:"\ed8b"
}
.icon-16-lastPage-mono:before{
    font-size:16px;
    content:"\ed8c"
}
.icon-16-lightbulb-mono:before{
    font-size:16px;
    content:"\ed8d"
}
.icon-16-link-mono:before{
    font-size:16px;
    content:"\ed8e"
}
.icon-16-location-bold:before{
    font-size:16px;
    content:"\ed8f"
}
.icon-16-location-mono:before{
    font-size:16px;
    content:"\ed90"
}
.icon-16-lock-closed-bold:before{
    font-size:16px;
    content:"\ed91"
}
.icon-16-lock-closed-mono:before{
    font-size:16px;
    content:"\ed92"
}
.icon-16-lock-open-bold:before{
    font-size:16px;
    content:"\ed93"
}
.icon-16-lock-open-mono:before{
    font-size:16px;
    content:"\ed94"
}
.icon-16-logBook-bold:before{
    font-size:16px;
    content:"\ed95"
}
.icon-16-magnetize-bold:before{
    font-size:16px;
    content:"\ed96"
}
.icon-16-magnetize-mono:before{
    font-size:16px;
    content:"\ed97"
}
.icon-16-measure-bold:before{
    font-size:16px;
    content:"\ed98"
}
.icon-16-measure-mono:before{
    font-size:16px;
    content:"\ed99"
}
.icon-16-minus-bold:before{
    font-size:16px;
    content:"\ed9a"
}
.icon-16-minus-mono:before{
    font-size:16px;
    content:"\ed9b"
}
.icon-16-money-canadianDollar-bold:before{
    font-size:16px;
    content:"\ed9c"
}
.icon-16-money-dollar-bold:before{
    font-size:16px;
    content:"\ed9d"
}
.icon-16-money-euro-bold:before{
    font-size:16px;
    content:"\ed9e"
}
.icon-16-money-pound-bold:before{
    font-size:16px;
    content:"\ed9f"
}
.icon-16-money-pound-mono:before{
    font-size:16px;
    content:"\eda0"
}
.icon-16-monitor-bold:before{
    font-size:16px;
    content:"\eda1"
}
.icon-16-monitor-mono:before{
    font-size:16px;
    content:"\eda2"
}
.icon-16-moreHori-bold:before{
    font-size:16px;
    content:"\eda3"
}
.icon-16-moreHori-mono:before{
    font-size:16px;
    content:"\eda4"
}
.icon-16-moreVert-bold:before{
    font-size:16px;
    content:"\eda5"
}
.icon-16-moreVert-mono:before{
    font-size:16px;
    content:"\eda6"
}
.icon-16-moreWindows-bold:before{
    font-size:16px;
    content:"\eda7"
}
.icon-16-moreWindows-mono:before{
    font-size:16px;
    content:"\eda8"
}
.icon-16-move-mono:before{
    font-size:16px;
    content:"\eda9"
}
.icon-16-multiInstrument-bold:before{
    font-size:16px;
    content:"\edaa"
}
.icon-16-multiInstrument-mono:before{
    font-size:16px;
    content:"\edab"
}
.icon-16-newWindow-bold:before{
    font-size:16px;
    content:"\edac"
}
.icon-16-newWindow-mono:before{
    font-size:16px;
    content:"\edad"
}
.icon-16-note-mono:before{
    font-size:16px;
    content:"\edae"
}
.icon-16-pause-bold:before{
    font-size:16px;
    content:"\edaf"
}
.icon-16-pause-mono:before{
    font-size:16px;
    content:"\edb0"
}
.icon-16-phone-bold:before{
    font-size:16px;
    content:"\edb1"
}
.icon-16-phoneVolume-bold:before{
    font-size:16px;
    content:"\edb2"
}
.icon-16-pieChart-bold:before{
    font-size:16px;
    content:"\edb3"
}
.icon-16-pieChart-mono:before{
    font-size:16px;
    content:"\edb4"
}
.icon-16-pin-bold:before{
    font-size:16px;
    content:"\edb5"
}
.icon-16-pin-mono:before{
    font-size:16px;
    content:"\edb6"
}
.icon-16-play-bold:before{
    font-size:16px;
    content:"\edb7"
}
.icon-16-play-mono:before{
    font-size:16px;
    content:"\edb8"
}
.icon-16-playButton-bold:before{
    font-size:16px;
    content:"\edb9"
}
.icon-16-playButton-mono:before{
    font-size:16px;
    content:"\edba"
}
.icon-16-plus-bold:before{
    font-size:16px;
    content:"\edbb"
}
.icon-16-plus-mono:before{
    font-size:16px;
    content:"\edbc"
}
.icon-16-power-bold:before{
    font-size:16px;
    content:"\edbd"
}
.icon-16-power-mono:before{
    font-size:16px;
    content:"\edbe"
}
.icon-16-print-bold:before{
    font-size:16px;
    content:"\edbf"
}
.icon-16-print-mono:before{
    font-size:16px;
    content:"\edc0"
}
.icon-16-profile-bold:before{
    font-size:16px;
    content:"\edc1"
}
.icon-16-profile-mono:before{
    font-size:16px;
    content:"\edc2"
}
.icon-16-profileAdd-bold:before{
    font-size:16px;
    content:"\edc3"
}
.icon-16-profileAdd-mono:before{
    font-size:16px;
    content:"\edc4"
}
.icon-16-profileDelete-bold:before{
    font-size:16px;
    content:"\edc5"
}
.icon-16-profileDelete-mono:before{
    font-size:16px;
    content:"\edc6"
}
.icon-16-prohibited-bold:before{
    font-size:16px;
    content:"\edc7"
}
.icon-16-prohibited-mono:before{
    font-size:16px;
    content:"\edc8"
}
.icon-16-promotions-bold:before{
    font-size:16px;
    content:"\edc9"
}
.icon-16-promotions-mono:before{
    font-size:16px;
    content:"\edca"
}
.icon-16-pushPin-bold:before{
    font-size:16px;
    content:"\edcb"
}
.icon-16-qr-bold:before{
    font-size:16px;
    content:"\edcc"
}
.icon-16-qr-mono:before{
    font-size:16px;
    content:"\edcd"
}
.icon-16-question-bold:before{
    font-size:16px;
    content:"\edce"
}
.icon-16-question-mono:before{
    font-size:16px;
    content:"\edcf"
}
.icon-16-random-bold:before{
    font-size:16px;
    content:"\edd0"
}
.icon-16-random-mono:before{
    font-size:16px;
    content:"\edd1"
}
.icon-16-rating-bold:before{
    font-size:16px;
    content:"\edd2"
}
.icon-16-recycle-bold:before{
    font-size:16px;
    content:"\edd3"
}
.icon-16-recycle-mono:before{
    font-size:16px;
    content:"\edd4"
}
.icon-16-refresh-bold:before{
    font-size:16px;
    content:"\edd5"
}
.icon-16-refresh-mono:before{
    font-size:16px;
    content:"\edd6"
}
.icon-16-repeat-bold:before{
    font-size:16px;
    content:"\edd7"
}
.icon-16-repeat-mono:before{
    font-size:16px;
    content:"\edd8"
}
.icon-16-resizeLarge-bold:before{
    font-size:16px;
    content:"\edd9"
}
.icon-16-resizeLarge-mono:before{
    font-size:16px;
    content:"\edda"
}
.icon-16-resizeSmall-bold:before{
    font-size:16px;
    content:"\eddb"
}
.icon-16-resizeSmall-mono:before{
    font-size:16px;
    content:"\eddc"
}
.icon-16-resource-bold:before{
    font-size:16px;
    content:"\eddd"
}
.icon-16-restart-bold:before{
    font-size:16px;
    content:"\edde"
}
.icon-16-restart-mono:before{
    font-size:16px;
    content:"\eddf"
}
.icon-16-return-bold:before{
    font-size:16px;
    content:"\ede0"
}
.icon-16-return-mono:before{
    font-size:16px;
    content:"\ede1"
}
.icon-16-reverseLeft-mono:before{
    font-size:16px;
    content:"\ede2"
}
.icon-16-reverseRight-mono:before{
    font-size:16px;
    content:"\ede3"
}
.icon-16-rewind-bold:before{
    font-size:16px;
    content:"\ede4"
}
.icon-16-rewind-mono:before{
    font-size:16px;
    content:"\ede5"
}
.icon-16-search-bold:before{
    font-size:16px;
    content:"\ede6"
}
.icon-16-search-mono:before{
    font-size:16px;
    content:"\ede7"
}
.icon-settings-alt-bold:before{
    font-size:16px;
    content:"\ede8"
}
.icon-16-settings-alt-mono:before{
    font-size:16px;
    content:"\ede9"
}
.icon-16-settings-bold:before{
    font-size:16px;
    content:"\edea"
}
.icon-16-settings-mono:before{
    font-size:16px;
    content:"\edeb"
}
.icon-16-share-alt-mono:before{
    font-size:16px;
    content:"\edec"
}
.icon-16-share-bold:before{
    font-size:16px;
    content:"\eded"
}
.icon-16-share-mono:before{
    font-size:16px;
    content:"\edee"
}
.icon-16-shipping-bold:before{
    font-size:16px;
    content:"\edef"
}
.icon-16-signal-bold:before{
    font-size:16px;
    content:"\edf0"
}
.icon-16-signal-mono:before{
    font-size:16px;
    content:"\edf1"
}
.icon-16-sleep-bold:before{
    font-size:16px;
    content:"\edf2"
}
.icon-16-sortArrows-bold:before{
    font-size:16px;
    content:"\edf3"
}
.icon-16-sortArrows-mono:before{
    font-size:16px;
    content:"\edf4"
}
.icon-16-speaker-1-bold:before{
    font-size:16px;
    content:"\edf5"
}
.icon-16-speaker-1-mono:before{
    font-size:16px;
    content:"\edf6"
}
.icon-16-speaker-2-bold:before{
    font-size:16px;
    content:"\edf7"
}
.icon-16-speaker-2-mono:before{
    font-size:16px;
    content:"\edf8"
}
.icon-16-speaker-full-bold:before{
    font-size:16px;
    content:"\edf9"
}
.icon-16-speaker-full-mono:before{
    font-size:16px;
    content:"\edfa"
}
.icon-16-speaker-off-bold:before{
    font-size:16px;
    content:"\edfb"
}
.icon-16-speaker-off-mono:before{
    font-size:16px;
    content:"\edfc"
}
.icon-16-stop-bold:before{
    font-size:16px;
    content:"\edfd"
}
.icon-16-stop-mono:before{
    font-size:16px;
    content:"\edfe"
}
.icon-16-stopwatch-bold:before{
    font-size:16px;
    content:"\edff"
}
.icon-16-stopwatch-mono:before{
    font-size:16px;
    content:"\ee00"
}
.icon-16-success-bold:before{
    font-size:16px;
    content:"\ee01"
}
.icon-16-success-mono:before{
    font-size:16px;
    content:"\ee02"
}
.icon-16-support-bold:before{
    font-size:16px;
    content:"\ee03"
}
.icon-16-table-bold:before{
    font-size:16px;
    content:"\ee04"
}
.icon-16-table-mono:before{
    font-size:16px;
    content:"\ee05"
}
.icon-16-tag-bold:before{
    font-size:16px;
    content:"\ee06"
}
.icon-16-tag-mono:before{
    font-size:16px;
    content:"\ee07"
}
.icon-16-techDocumentation-bold:before{
    font-size:16px;
    content:"\ee08"
}
.icon-16-thumbsDowon-bold:before{
    font-size:16px;
    content:"\ee09"
}
.icon-16-thumbsUp-bold:before{
    font-size:16px;
    content:"\ee0a"
}
.icon-16-tint-bold:before{
    font-size:16px;
    content:"\ee0b"
}
.icon-16-tint-mono:before{
    font-size:16px;
    content:"\ee0c"
}
.icon-16-unreturn-bold:before{
    font-size:16px;
    content:"\ee0d"
}
.icon-16-unreturn-mono:before{
    font-size:16px;
    content:"\ee0e"
}
.icon-16-unshare-bold:before{
    font-size:16px;
    content:"\ee0f"
}
.icon-16-unshare-mono:before{
    font-size:16px;
    content:"\ee10"
}
.icon-16-up-arrow-bold-copy2 .path1:before{
    font-size:16px;
    content:"\ee11";
    color:#fff46d
}
.icon-16-up-arrow-bold-copy2 .path2:before{
    font-size:16px;
    content:"\ee12";
    margin-left:-1em;
    color:#598db5
}
.icon-16-up-arrow-bold-copy2 .path3:before{
    font-size:16px;
    content:"\ee13";
    margin-left:-1em;
    color:#fff
}
.icon-16-up-arrow-bold-copy2 .path4:before{
    font-size:16px;
    content:"\ee14";
    margin-left:-1em;
    color:#ed1c24
}
.icon-16-up-arrow-bold-copy2 .path5:before{
    font-size:16px;
    content:"\ee15";
    margin-left:-1em;
    color:#222
}
.icon-16-upload-bold:before{
    font-size:16px;
    content:"\ee16"
}
.icon-16-upload-mono:before{
    font-size:16px;
    content:"\ee17"
}
.icon-16-vcard-bold:before{
    font-size:16px;
    content:"\ee18"
}
.icon-16-vcard-mono:before{
    font-size:16px;
    content:"\ee19"
}
.icon-16-video-bold:before{
    font-size:16px;
    content:"\ee1a"
}
.icon-16-video-mono:before{
    font-size:16px;
    content:"\ee1b"
}
.icon-16-view-off-mono:before{
    font-size:16px;
    content:"\ee1c"
}
.icon-16-view-on-mono:before{
    font-size:16px;
    content:"\ee1d"
}
.icon-16-warning-bold:before{
    font-size:16px;
    content:"\ee1e"
}
.icon-16-webinar-bold:before{
    font-size:16px;
    content:"\ee1f"
}
.icon-16-webinar-mono:before{
    font-size:16px;
    content:"\ee20"
}
.icon-16-wireless-alt-bold:before{
    font-size:16px;
    content:"\ee21"
}
.icon-16-wireless-bold:before{
    font-size:16px;
    content:"\ee22"
}
.icon-16-zoomIn-bold:before{
    font-size:16px;
    content:"\ee23"
}
.icon-16-zoomIn-mono:before{
    font-size:16px;
    content:"\ee24"
}
.icon-16-zoomOut-mono:before{
    font-size:16px;
    content:"\ee25"
}
.icon-16-zoomOut-bold:before{
    font-size:16px;
    content:"\ee26"
}
.icon-8-alert:before{
    font-size:8px;
    content:"\ee27"
}
.icon-8-arrow-down:before{
    font-size:8px;
    content:"\ee28"
}
.icon-8-arrow-left:before{
    font-size:8px;
    content:"\ee29"
}
.icon-8-arrow-right:before{
    font-size:8px;
    content:"\ee2a"
}
.icon-8-arrow-up:before{
    font-size:8px;
    content:"\ee2b"
}
.icon-8-caret-down:before{
    font-size:8px;
    content:"\ee2c"
}
.icon-8-caret-left:before{
    font-size:8px;
    content:"\ee2d"
}
.icon-8-caret-right:before{
    font-size:8px;
    content:"\ee2e"
}
.icon-8-caret-up:before{
    font-size:8px;
    content:"\ee2f"
}
.icon-8-checkmark:before{
    font-size:8px;
    content:"\ee30"
}
.icon-8-chevron-down:before{
    font-size:8px;
    content:"\ee31"
}
.icon-8-chevron-left:before{
    font-size:8px;
    content:"\ee32"
}
.icon-8-chevron-right:before{
    font-size:8px;
    content:"\ee33"
}
.icon-8-chevron-up:before{
    font-size:8px;
    content:"\ee34"
}
.icon-8-close:before{
    font-size:8px;
    content:"\ee35"
}
.icon-8-download:before{
    font-size:8px;
    content:"\ee36"
}
.icon-8-eject:before{
    font-size:8px;
    content:"\ee37"
}
.icon-8-fastBackward:before{
    content:"\ee38";
    font-size:8px
}
.icon-8-fastFoward:before{
    font-size:8px;
    content:"\ee39"
}
.icon-8-favorites:before{
    font-size:8px;
    content:"\ee3a"
}
.icon-8-film:before{
    font-size:8px;
    content:"\ee3b"
}
.icon-8-foward:before{
    font-size:8px;
    content:"\ee3c"
}
.icon-8-info:before{
    font-size:8px;
    content:"\ee3d"
}
.icon-8-lastPage:before{
    font-size:8px;
    content:"\ee3e"
}
.icon-8-lastPage:before{
    font-size:8px;
    content:"\ee3f"
}
.icon-8-minus:before{
    font-size:8px;
    content:"\ee40"
}
.icon-8-moreHori:before{
    font-size:8px;
    content:"\ee41"
}
.icon-8-moreVert:before{
    font-size:8px;
    content:"\ee42"
}
.icon-8-pause:before{
    font-size:8px;
    content:"\ee43"
}
.icon-8-play:before{
    font-size:8px;
    content:"\ee44"
}
.icon-8-plus:before{
    font-size:8px;
    content:"\ee45"
}
.icon-8-question:before{
    font-size:8px;
    content:"\ee46"
}
.icon-8-rating:before{
    font-size:8px;
    content:"\ee47"
}
.icon-8-sortArrows:before{
    font-size:8px;
    content:"\ee48"
}
.icon-8-stop-bold:before{
    font-size:8px;
    content:"\ee49"
}
.icon-8-stop-mono:before{
    font-size:8px;
    content:"\ee4a"
}
.icon-8-upload:before{
    font-size:8px;
    content:"\ee4b"
}
